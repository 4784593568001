import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import Styled from 'styled-components';

import HcpUpdateEmailForm from '@/components/business/analytics/hcp/hcp-update-email-form.jsx';
import LoadingView from '@/components/static/loading-view.tsx';
import {AlertTypeEnum} from '@/enum/alert-type-enum.jsx';
import emailSent from '@/public/images/email_sent.svg';
import {BackendApiService} from '@/services/backend-api-service.js';
import {FeedbackAlertsService} from '@/services/feedback-alerts-service.js';
import {colorPalette} from '@/themes/darkmode.js';

class HcpUpdateEmail extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        hcp: PropTypes.object.isRequired,
        onSaved: PropTypes.func
    };

    state = {
        loading: false,
        displayEmailChangedSuccessMessage: false,
    };

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={this.props.className}>
                {state.displayEmailChangedSuccessMessage &&
                    <div className="email-changed-success">
                        <img src={emailSent} width={300} alt={t(['Email sent', 'hcpUpdateEmail.emailSentAlt'])} />
                        <p>
                            {t(['Your email address has been changed!', 'hcpUpdateEmail.emailChangedSuccess.hasChanged'])}
                            <br />
                            {t(['We sent you an email with the next instructions.', 'hcpUpdateEmail.emailChangedSuccess.nextInstructions'])}
                        </p>
                    </div>
                }
                {!state.loading && !state.displayEmailChangedSuccessMessage &&
                    <div>
                        <h3>{t(['Change my email address', 'hcpUpdateEmail.title'])}</h3>
                        <p className="email-change-current">
                            {t(['Current email address:', 'hcpUpdateEmail.currentEmailAddress'])}<br/>
                            {props.hcp.email}
                        </p>
                        <HcpUpdateEmailForm hcp={props.hcp} onSubmit={(hcp) => this.handleHcpUpdateEmail(hcp)} />
                    </div>
                }
                {state.loading &&
                    <LoadingView />
                }
            </div>
        );
    }

    async handleHcpUpdateEmail(hcp) {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'hcp',
            modelName: 'updateHcpEmail',
            data: {
                hcp: hcp
            }
        })
            .then((hcp) => {
                FeedbackAlertsService.add(AlertTypeEnum.SUCCESS, 'updateHcpEmail', this.props.t(['Email changed!', 'hcpUpdateEmail.success']));
                this.setState(state => ({
                    ...state,
                    loading: false,
                    displayEmailChangedSuccessMessage: true,
                }));

                if(this.props.onSaved) {
                    this.props.onSaved(hcp);
                }
                return hcp;
            })
            .catch(err => {
                console.error(err);
                this.setState(state => ({
                    ...state,
                    loading: false,
                }));
            });
    }

    componentWillUnmount() {
        this.setState(state => ({
            ...state,
            loading: false,
            errorMessage: '',
            displayEmailChangedSuccessMessage: false,
        }));
    }
}

//language=SCSS
HcpUpdateEmail = Styled(HcpUpdateEmail)`
    & {
        .email-change-current {
            background: ${colorPalette.thirdBackground};
            padding: 10px 20px;
            border-radius: 5px;
            margin-top: 20px;
        }
        
        .email-changed-success {
            margin:0 auto;
            margin-bottom:50px;
            text-align:center;
            font-size:18px;
            img {
                margin-bottom:40px;
            }
        }
    }
`;


export default withTranslation()(HcpUpdateEmail);

