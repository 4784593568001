import {JSXElementConstructor, useEffect, useState} from 'react';
import sorter from 'sort-nested-json';

import {TableSortableBody} from '@/components/table/table-sortable-body';
import {TableSortableColumn} from '@/components/table/table-sortable-column';
import {TableSortableHead} from '@/components/table/table-sortable-head';
import ComponentErrorMessage from '@/components/widgets/component-error-message';

function getDefaultSorting<DataItem>(defaultTableData: DataItem[], defaultSorting: TableSorting): DataItem[] {
    if (defaultTableData) {
        let defaultSortedTable: DataItem[];
        if (defaultSorting) {
            if (defaultSorting.sortByOrder === 'desc') {
                defaultSortedTable = sorter.sort(defaultTableData).desc(defaultSorting.accessor);
            }
            else {
                defaultSortedTable = sorter.sort(defaultTableData).asc(defaultSorting.accessor);
            }
        }
        else {
            defaultSortedTable = sorter.sort(defaultTableData).asc('id');
        }
        return defaultSortedTable;
    }
    else {
        return [];
    }
}

function useSortableTable<DataItem>(data: DataItem[], defaultSorting: TableSorting): [ DataItem[], (accessor: string, sortOrder : 'asc' | 'desc' | 'default') => void ] {
    const [tableData, setTableData] = useState<DataItem[]>(getDefaultSorting(data, defaultSorting));
    useEffect(() => {
        setTableData(data);
    }, [data]);

    const handleSorting = (accessor: string, sortOrder : 'asc' | 'desc' | 'default') => {
        if (accessor) {
            let sorted;
            if (sortOrder === 'desc') {
                sorted = sorter.sort(tableData).desc(accessor);
            }
            else {
                sorted = sorter.sort(tableData).asc(accessor);
            }
            setTableData(sorted);
        }
        //return accessor;
    };

    return [tableData, handleSorting];
}

type TableSorting = {
    sortByOrder: 'asc' | 'desc' | 'default';
    accessor: string;
}

type TableSortableProps<DataItem> = {
    TableBodyRowComponent: JSXElementConstructor<{
        data: DataItem;
    }>;
    columns: TableSortableColumn[];
    data: DataItem[];
    defaultSorting: TableSorting;
}

function TableSortable<DataItem>({ TableBodyRowComponent, columns, data, defaultSorting }: TableSortableProps<DataItem>) {
    const [tableData, handleSorting] = useSortableTable(data, defaultSorting);

    if (data && columns && defaultSorting) {
        return (
            <table>
                <TableSortableHead columns={columns} handleSorting={handleSorting} />
                <TableSortableBody tableData={tableData} TableBodyRowComponent={TableBodyRowComponent} />
            </table>
        );
    }
    else {
        return <ComponentErrorMessage component="TableSortable" />;
    }
}

export {TableSortable};
