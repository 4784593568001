import {useTranslation} from 'react-i18next';

import InfoTooltip from '@/components/buttons/info-tooltip';

function NoDataCell() {
    const { t } = useTranslation();
    return (<>
        <em>{t(['No data', 'pages.patients.noValue'])}</em>
        <InfoTooltip className="noDataTooltip" title={t(['No data', 'pages.patients.noValue'])} overlayPosition={'bottomRight'}>
            <p>{t(['No reliable data available for this recording', 'pages.patients.noValueText'])}</p>
        </InfoTooltip>
    </>);
}

export {NoDataCell};
