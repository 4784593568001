import {subDays} from 'date-fns';
import {PatientQueryServiceClient} from 'gabi-api-ts/v2/patient/query/patient_query.client';
import styled from 'styled-components';

import PatientsInactiveTable from '@/components/business/analytics/patients/patients-inactive-table';
import LoadingView from '@/components/static/loading-view';
import {useBackendQuery} from '@/hooks/use-backend-query';
import {formatJSDateToApiDateTs} from '@/services/api-requests/requests-utils-ts';
import {NewDateUtil} from '@/util/new-date-util';

const PatientsInactive = () => {
    const dateTo = NewDateUtil();
    const dateFrom = subDays(dateTo, 6);
    const [activePatientsLoading, activePatients] = useBackendQuery({
        serviceClient: PatientQueryServiceClient,
        query: PatientQueryServiceClient.prototype.getPatientsWithRecording,
        data: {
            from: formatJSDateToApiDateTs(dateFrom),
            to: formatJSDateToApiDateTs(dateTo),
        },
        memoize: true,
    });
    const [allPatientsLoading, allPatients] = useBackendQuery({
        serviceClient: PatientQueryServiceClient,
        query: PatientQueryServiceClient.prototype.getPatients,
        data: {},
        memoize: true,
    });
    
    const loading = (
        (activePatientsLoading || !activePatients) ||
        (allPatientsLoading || !allPatients)
    );
    
    let inactivePatients/*: Patients*/ = [];
    if (activePatients && allPatients) {
        inactivePatients = allPatients.patient.filter(patient => {
            return -1 === (activePatients.patient.findIndex(activePatient => activePatient.patientId.id === patient.patientId.id));
        });
    }

    if (!loading) {
        return (
            <StyledWrapper>
                <PatientsInactiveTable patients={inactivePatients} />
            </StyledWrapper>
        );
    }
    else {
        return <LoadingView size="large" />;
    }
};

PatientsInactive.propTypes = {
};

//language=SCSS
const StyledWrapper = styled.div`
& {
    padding-bottom: 50px;
}
`;

export {PatientsInactive};
