import {isValid, subDays} from 'date-fns';
import deepEqual from 'deep-eql';
import {PatientQueryServiceClient} from 'gabi-api-ts/v2/patient/query/patient_query.client';
import {SignalType} from 'gabi-api-ts/v2/signal/query/signal_query';
import {SignalQueryServiceClient} from 'gabi-api-ts/v2/signal/query/signal_query.client';
import {atNoon, formatFullTime} from 'gabi-web-common/util/date-util';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {CartesianGrid, Line, LineChart, ReferenceArea, ReferenceLine, Tooltip, XAxis, YAxis} from 'recharts';
import Styled from 'styled-components';

import GuidedTourTimeline from '@/components/business/analytics/guidedTour/guided-tour-timeline';
import {HcpTutorialStatus} from '@/components/business/analytics/hcp/hcp-tutorial-status';
import {ChartAxisTickColored} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-colored';
import {TimelineChart} from '@/components/business/analytics/timeline/timeline-chart';
import {
    TimelineTooltipActigraphy,
    TimelineTooltipHR,
    TimelineTooltipSPO2,
} from '@/components/business/analytics/timeline/timeline-chart-tooltip';
import {TimelineScrollbar} from '@/components/business/analytics/timeline/timeline-scrollbar';
import {TimelineSidebar} from '@/components/business/analytics/timeline/timeline-sidebar';
import {TimelineTick} from '@/components/business/analytics/timeline/timeline-tick';
import {ErrorMessage} from '@/components/form/error-message';
import {Autosized} from '@/components/layout/autosized';
import LoadingView from '@/components/static/loading-view';
import {Page} from '@/decorators/page';
import {withBackendQuery} from '@/decorators/with-backend-query';
import withRouter from '@/decorators/withRouter';
import baby_sitting_skinType_1 from '@/public/images/baby_sitting_skin_1.svg';
import baby_sitting_skinType_2 from '@/public/images/baby_sitting_skin_2.svg';
import baby_sitting_skinType_3 from '@/public/images/baby_sitting_skin_3.svg';
import baby_sitting_skinType_4 from '@/public/images/baby_sitting_skin_4.svg';
import baby_sitting_skinType_5 from '@/public/images/baby_sitting_skin_5.svg';
import baby_sitting_skinType_6 from '@/public/images/baby_sitting_skin_6.svg';
import {formatJSDateToApiDateTs} from '@/services/api-requests/requests-utils-ts';
import {BackendApiService} from '@/services/backend-api-service';
import {withTutorialStatus} from '@/services/tutorial-service';
import {colorPalette} from '@/themes/darkmode';
import {isDateStringValid} from '@/util/date-utils';
import {NewDateUtil} from '@/util/new-date-util';
import {getSignalTypeIdentifier} from '@/util/signal-type-util';
import {formatDateForUrl} from '@/util/url-util';

import '@/stylesheets/datepicker-darkmode-theme.css';

const babySittingSkinType = {
    1: baby_sitting_skinType_1,
    2: baby_sitting_skinType_2,
    3: baby_sitting_skinType_3,
    4: baby_sitting_skinType_4,
    5: baby_sitting_skinType_5,
    6: baby_sitting_skinType_6,
};

function checkOrder(arr) {
    for (let i = 1; i < arr.length; i++) {
        if (arr[i - 1].secondsSince12pm > arr[i].secondsSince12pm) {
            console.warn('SIGNAL IS NOT ORDERED');
            console.warn(`Index: ${i - 1}, Values: ${arr[i - 1].secondsSince12pm} and ${arr[i].secondsSince12pm}`);
            console.warn(`Index: ${i - 1}, Values: ${new Date(arr[i - 1].secondsSince12pm)} and ${new Date(arr[i].secondsSince12pm)}`);
            console.warn(`Index: ${i - 1}, Values: ${JSON.stringify(arr[i - 1].atOrig)} and ${JSON.stringify(arr[i].atOrig)}`);
        }
    }
}

function wrapWithPage(Component) {
    return Page({
        name: 'Daily biometrics',
        pagePath: props => ([
            {
                route: '/patients/',
                name: 'Patients'
            },
            {
                route: `/patients/${props.params.id_patient}/healthReport`,
                name: 'Health Report'
            }
        ])
    })(Component);
}

function withDaysWithData(Component) {
    return withBackendQuery('daysWithDataLoading', 'daysWithData', 'daysWithDataError', props => {
        return ({
            serviceClient: SignalQueryServiceClient,
            query: SignalQueryServiceClient.prototype.getDaysWithSignalData,
            memoize: true,
            data: {
                patientId: {id: props.params.id_patient},
                from: formatJSDateToApiDateTs(new Date('2021/01/01 00:00:00')),
                to: formatJSDateToApiDateTs(NewDateUtil())
            },
        });
    })(Component);
}

function withEventConfig(Component) {
    return withBackendQuery('eventConfigurationLoading', 'eventConfiguration', 'eventConfigurationError', props => {
        return ({
            serviceClient: SignalQueryServiceClient,
            query: SignalQueryServiceClient.prototype.getEventConfiguration,
            memoize: true,
            data: {patientId: {id: props.params.id_patient}},
        });
    })(Component);
}

function withPatientData(Component) {
    return withBackendQuery('patientLoading', 'patient', 'patientError', props => ({
        serviceClient: PatientQueryServiceClient,
        query: PatientQueryServiceClient.prototype.get,
        memoize: true,
        data: { id: props.params.id_patient },
    }))(Component);
}

function computeChartOverviewBlocksReliable(signal) {
    const chartOverviewBlocksReliable = [];
    if (signal.length > 0) {
        const firstReliablePoint = signal.find(item => item.reliability);
        if (firstReliablePoint) {
            let prevT = firstReliablePoint.secondsSince12pm;
            let curBlockStart = prevT;
            signal.forEach(item => {
                if (item.reliability) {
                    if (item.secondsSince12pm > prevT + 120) {
                        chartOverviewBlocksReliable.push({
                            start: curBlockStart,
                            end: prevT,
                        });
                        curBlockStart = item.secondsSince12pm;
                    }
                    prevT = item.secondsSince12pm;
                }
            });
            chartOverviewBlocksReliable.push({
                start: curBlockStart,
                end: prevT,
            });
        }
    }
    return chartOverviewBlocksReliable;
}

class PatientTimelinePage extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        daysWithData: PropTypes.object,
        eventConfiguration: PropTypes.object,
        eventConfigurationLoading: PropTypes.bool.isRequired,
        patient: PropTypes.object,
        patientError: PropTypes.object,
        patientLoading: PropTypes.bool.isRequired,
        navigate: PropTypes.func,
        params: PropTypes.object,
        tutorialStatus: PropTypes.any,
    };

    static zoomLevel = {
        value: 200/300,
        label: '5min',
    };
    
    chartOverviewBlocks = [];
    chartOverviewBlocksReliableSPO2 = [];
    chartOverviewBlocksReliableHR = [];

    state = {
        chartAreaWidth: 0, // Got from <Autosized> to capture the chart width
        displayModalZoom: false,
        errorMessage: null,
        fetchingSignalsAndEvents: false,
        medicalEvents: null,
        ranges: null,
        selectedDateTime: 0,
        signals: null,
        subcharts: {},
        tooltipData: {},
        visibleStartTime: 0,
        zoomData: {},
    };

    constructor(props) {
        super(props);

        autoBind(this);

        // Enable charts
        this.state.subcharts[SignalType.Signal_PR] = true;
        this.state.subcharts[SignalType.Signal_SPO2] = true;
        this.state.subcharts[SignalType.Signal_Actigraphy] = true;

        // Set start date from URL
        if (!props.params.date) {
            this.state.selectedDateTime = props.daysWithData.day[props.daysWithData.day.length - 1].getTime();
        }
        else if (!isDateStringValid(props.params.date)) {
            setTimeout(() => {
                props.navigate(`/patients/${props.params.id_patient}/timeline`, { replace: true });
            }, 50);
        }
        else {
            const urlDate = this.parseUrlDateAndTime();
            const hours = urlDate.getHours();
            if (hours >= 0 && hours < 12) {
                const dayBeforeUrlDate = subDays(urlDate, 1);
                this.state.selectedDateTime = atNoon(dayBeforeUrlDate).getTime();
            }
            else {
                this.state.selectedDateTime = atNoon(urlDate).getTime();
            }
        }
    }
    
    parseUrlDateAndTime() {
        const params = this.props.params;
        const urlDateStr = params.date.replace(/-/g, '/');
        let urlTimeStr = (!params.time) ? '12:00:00' : params.time;
        if (urlTimeStr.length <= 2) {
            urlTimeStr += ':00';
        }
        const urlDateTimeStr = `${urlDateStr} ${urlTimeStr}`;
        return new Date(urlDateTimeStr);
    }

    componentDidMount() {
        document.addEventListener('mouseover', this.handleDocumentMouseOver);
        if (this.props.eventConfiguration) {
            this.fetchSignalsAndEvents();
        }
    }
    
    componentDidUpdate(prevProps) {
        if (!deepEqual(prevProps.eventConfiguration, this.props.eventConfiguration)) {
            if (this.props.eventConfiguration) {
                this.fetchSignalsAndEvents();
            }
        }
    }

    async fetchSignalsAndEvents() {
        const state = this.state;
        const dateFrom = new Date(state.selectedDateTime);
        const dateTo = new Date(state.selectedDateTime);

        this.setState(state => ({
            ...state,
            fetchingSignalsAndEvents: true,
        }));

        BackendApiService.getRequest({
            domain: 'signal',
            modelName: 'getSignal',
            data: {
                patientId: this.props.params.id_patient,
                from : dateFrom,
                to: dateTo
            }
        })
            .then((response) => {
                const ranges = response.rangesList;
                const signals = response.blocksList;
                const medicalEvents = response.eventsList;
                checkOrder(signals.hr);
                
                // Computing overview blocks
                this.chartOverviewBlocks = [];
                if (signals.spo2.length > 0) {
                    let prevT = signals.spo2[0].secondsSince12pm;
                    let curBlockStart = prevT;
                    signals.spo2.forEach(item => {
                        if (item.secondsSince12pm > prevT + 300) {
                            this.chartOverviewBlocks.push({
                                start: curBlockStart,
                                end: prevT,
                            });
                            curBlockStart = item.secondsSince12pm;
                        }
                        prevT = item.secondsSince12pm;
                    });
                    this.chartOverviewBlocks.push({
                        start: curBlockStart,
                        end: prevT,
                    });
                }
                
                // Computing overview blocks with reliable data
                this.chartOverviewBlocksReliableSPO2 = computeChartOverviewBlocksReliable(signals.spo2);
                this.chartOverviewBlocksReliableHR = computeChartOverviewBlocksReliable(signals.hr);
                
                if (!this.props.params.time) {
                    if (this.chartOverviewBlocks.length > 0) {
                        this.setState(state => ({
                            ...state,
                            visibleStartTime: this.chartOverviewBlocks[0]?.start,
                        }));
                    }
                }
                else {
                    const urlDate = this.parseUrlDateAndTime();
                    
                    const signalStartTime = this.chartOverviewBlocks[0]?.start;
                    const signalEndTime = this.chartOverviewBlocks[this.chartOverviewBlocks.length-1]?.end;

                    if (isValid(urlDate)) {
                        this.setState(state => ({
                            ...state,
                            visibleStartTime: (
                                Math.min(signalEndTime - state.chartAreaWidth / PatientTimelinePage.zoomLevel.value,
                                    Math.max(signalStartTime,
                                        (urlDate.getTime() - state.selectedDateTime) / 1000
                                    )
                                )
                            ),
                        }));
                    }
                }

                this.setState(state => ({
                    ...state,
                    ranges: ranges,
                    signals: this.alignSignals(signals),
                    medicalEvents: medicalEvents,
                    fetchingSignalsAndEvents: false,
                }));

                return response;
            })
            .catch(err => {
                console.error(err);
                this.setState(state => ({
                    ...state,
                    signals: null,
                    fetchingSignalsAndEvents: false,
                    errorMessage: err.message || 'Cannot get signals.'
                }));
            });
    }

    /**
     * Make sure all signals have the same number of points and have "unixTs" aligned
     * @param signals
     * @return {{}} aligned signals
     */
    alignSignals(signals) {
        // return signals;
        const prioritarySignals = [
            'spo2',
            'hr',
        ];
        let mainSignal = null;
        let mainSignalKey = null;
        for (let i=0; i < prioritarySignals.length; ++i) {
            if (signals[prioritarySignals[i]]) {
                mainSignalKey = prioritarySignals[i];
                mainSignal = signals[mainSignalKey];
                break;
            }
        }

        const outSignals = {};
        Object.keys(signals).forEach(signalKey => {
            const outSignal = [];
            const signal = signals[signalKey];
            if (signalKey === mainSignalKey) {
                outSignals[signalKey] = signal;
            }
            else {
                let j = 0;
                for (let i=0; i < mainSignal.length; ++i) {
                    const mainSignalPoint = mainSignal[i];

                    while (j < signal.length && signal[j].secondsSince12pm < mainSignalPoint.secondsSince12pm) {
                        ++j;
                    }
                    let value = (signal[j] || { value: null }).value;
                    let reliableValue = (signal[j] || { value: null }).reliableValue;
                    let reliability = (signal[j] || { reliability: null }).reliability;
                    if (!signal[j] || ((signal[j].secondsSince12pm - mainSignalPoint.secondsSince12pm) > 1) || (reliableValue && reliableValue.value) === 'noData') {
                        reliability = false;
                        value = 'noData';
                        if (reliableValue) {
                            reliableValue.value = 'noData';
                        }
                    }

                    outSignal.push({
                        ...mainSignalPoint,
                        value: value,
                        reliableValue: reliableValue,
                        reliability: reliability,
                    });
                }
                outSignals[signalKey] = outSignal;
            }
        });
        return outSignals;
    }

    checkSignalsPresence() {
        const signals = this.state.signals;
        return !(signals !== null && (signals.hr.length === 0 && signals.spo2.length === 0 && signals.actigraphy.length === 0));
    }

    render() {
        const props = this.props;
        const state = this.state;
        
        const visibleEndTime = state.visibleStartTime + state.chartAreaWidth / PatientTimelinePage.zoomLevel.value;

        const loading = (
            (props.eventConfigurationLoading) ||
            (props.patientLoading) ||
            (!props.daysWithData)
        );
        
        const hasPatientAccess = !props.patientError;
        const patient = props.patient;
        
        if (loading) {
            return (
                <div className={`${props.className}`}>
                    {hasPatientAccess && patient && props.daysWithData && props.eventConfiguration && (
                        <TimelineSidebar
                            patient={patient}
                            eventConfiguration={props.eventConfiguration}
                            daysWithData={props.daysWithData.day}
                            selectedDate={new Date(state.selectedDateTime)}
                            onDateSelected={this.handleSelectDate}
                            
                        />
                    )}
                    <LoadingView />
                </div>
            );
        }
        else if (!hasPatientAccess) {
            return (
                <div className="no-access-message-block">
                    <div className="info-message">You don&apos;t have access to this patient.</div>
                    <img src={baby_sitting_skinType_1 ?? ''} alt="Baby sitting" width="150" />
                </div>
            );
        }
        else if (state.errorMessage) {
            return (
                <ErrorMessage errorMessage={state.errorMessage} text="An error occurred" />
            );
        }
        else {
            return (
                <div className={`${props.className}`}>
                    {patient &&
                        <div>
                            {props.tutorialStatus.guidedTourSignals === HcpTutorialStatus.ENABLED &&
                                <GuidedTourTimeline />
                            }
                            <TimelineSidebar
                                patient={patient}
                                eventConfiguration={props.eventConfiguration}
                                daysWithData={props.daysWithData.day}
                                selectedDate={new Date(state.selectedDateTime)}
                                onDateSelected={this.handleSelectDate}
                            />
                            <div className="timeline-content-wrapper">
                                <div className={'timeline-content'}>
                                    {(!state.fetchingSignalsAndEvents && this.checkSignalsPresence() && state.medicalEvents &&
                                        <div className="timeline-scroll">
                                            <TimelineScrollbar
                                                startTime={0}
                                                endTime={24*3600}
                                                visibleStartTime={state.visibleStartTime}
                                                visibleEndTime={visibleEndTime}
                                                medicalEvents={state.medicalEvents}
                                                onScroll={this.handleChartScroll}
                                                onClick={this.handleChartScrollClick}
                                                dataBlocks={this.chartOverviewBlocks}
                                                dataBlocksReliableSPO2={this.chartOverviewBlocksReliableSPO2}
                                                dataBlocksReliableHR={this.chartOverviewBlocksReliableHR}
                                            />
                                        </div>
                                    )}
                                    <div className="timeline-charts">
                                        <Autosized component={this.renderMetricChart} />
                                    </div>
                                </div>
                            </div>

                            {(state.tooltipData?.value && state.tooltipData?.subchartType === SignalType.Signal_PR) && this.renderSubChartTooltip(
                                state.tooltipData, (
                                    <TimelineTooltipHR
                                        active={true}
                                        baseDate={state.selectedDateTime}
                                        payload={state.tooltipData}
                                    />
                                )
                            )}
                            {(state.tooltipData?.value && state.tooltipData?.subchartType === SignalType.Signal_SPO2) && this.renderSubChartTooltip(
                                state.tooltipData, (
                                    <TimelineTooltipSPO2
                                        active={true}
                                        baseDate={state.selectedDateTime}
                                        payload={state.tooltipData}
                                    />
                                )
                            )}
                            {(state.tooltipData?.value && state.tooltipData?.subchartType === SignalType.Signal_Actigraphy) && this.renderSubChartTooltip(
                                state.tooltipData, (
                                    <TimelineTooltipActigraphy
                                        active={true}
                                        baseDate={state.selectedDateTime}
                                        payload={state.tooltipData}
                                    />
                                )
                            )}

                            {(state.displayModalZoom && state.zoomData?.zoomSelectedSubchart === SignalType.Signal_PR) && this.renderSubChartZoom(SignalType.Signal_PR, state.signals?.hr, state.medicalEvents?.hr, props.eventConfiguration.configuration.hr, TimelineTooltipHR, 'Pulse Rate', 'bpm', 10)}
                            {(state.displayModalZoom && state.zoomData?.zoomSelectedSubchart === SignalType.Signal_SPO2) && this.renderSubChartZoom(SignalType.Signal_SPO2, state.signals?.spo2, state.medicalEvents?.spo2, props.eventConfiguration.configuration.spo2, TimelineTooltipSPO2, 'SpO2', '%', 2)}
                            {(state.displayModalZoom && state.zoomData?.zoomSelectedSubchart === SignalType.Signal_Actigraphy) && this.renderSubChartZoom(SignalType.Signal_Actigraphy, state.signals?.actigraphy, state.medicalEvents?.actigraphy, null, TimelineTooltipActigraphy, 'Movements', 'G',0.01)}
                        </div>
                    }
                    {!patient && (<>
                        <LoadingView />
                    </>)}
                </div>
            );
        }
    }

    renderSubChartTooltip(tooltipData, /*JSX.Element*/ content) {
        return (
            <div style={{
                'width': '220px',
                'position': 'absolute',
                'left': (tooltipData.positionX >= window.innerWidth / 2) ? tooltipData.positionX - 185 : tooltipData.positionX + 10,
                'top': tooltipData.positionY + 10}}
            >
                {content}
            </div>
        );
    }

    renderSubChartZoom(subchartType, data, medicalEvents, eventConfiguration, tooltip_, label, unit, padding) {
        const state = this.state;
        
        const tooltip = (props) => {
            return tooltip_({
                ...props,
                baseDate: state.selectedDateTime,
                payload: props.payload[props.payload.length-1]?.payload,
            });
        };
        
        const zoomedChartDomainY = [];
        const zoomedChartDomainX = [];
        const startTimestamp = state.zoomData?.zoomAreaStartTimestamp; //new Date(state.zoomData?.zoomAreaStartTimestamp).getUTCMilliseconds();

        // Get chart data for selected period
        const zoomedChartData = data.filter(item => (
            item.secondsSince12pm >= state.zoomData?.zoomAreaStartTimestamp &&
            item.secondsSince12pm <= state.zoomData?.zoomAreaEndTimestamp
        ));
        if (zoomedChartData.length <= 0) {
            return null;
        }

        // Get medical alerts for selected period
        const zoomedChartMedicalEvents = medicalEvents.filter(block => {
            return (
                (
                    block.fromSecondsSince12pm >= state.zoomData?.zoomAreaStartTimestamp &&
                    block.fromSecondsSince12pm <= state.zoomData?.zoomAreaEndTimestamp
                ) ||
                (
                    block.toSecondsSince12pm >= state.zoomData?.zoomAreaStartTimestamp &&
                    block.toSecondsSince12pm <= state.zoomData?.zoomAreaEndTimestamp
                )
            );
        });
        if (zoomedChartMedicalEvents.length > 0) {
            if (zoomedChartMedicalEvents[0].fromSecondsSince12pm < state.zoomData?.zoomAreaStartTimestamp) {
                zoomedChartMedicalEvents[0] = {
                    ...zoomedChartMedicalEvents[0],
                    fromSecondsSince12pm: state.zoomData?.zoomAreaStartTimestamp,
                };
            }
            if (zoomedChartMedicalEvents[zoomedChartMedicalEvents.length-1].toSecondsSince12pm > state.zoomData?.zoomAreaEndTimestamp) {
                zoomedChartMedicalEvents[zoomedChartMedicalEvents.length-1] = {
                    ...zoomedChartMedicalEvents[zoomedChartMedicalEvents.length-1],
                    toSecondsSince12pm: state.zoomData?.zoomAreaEndTimestamp,
                };
            }
        }

        // Get chart domain for selected period + padding
        let zoomedChartDomainMin = zoomedChartData[0].value;
        let zoomedChartDomainMax = zoomedChartData[0].value;
        zoomedChartData.forEach(item => {
            if (item.value < zoomedChartDomainMin) {
                zoomedChartDomainMin = item.value;
            }
            if (item.value > zoomedChartDomainMax) {
                zoomedChartDomainMax = item.value;
            }
        });
        
        // Add padding if relevant
        zoomedChartDomainMin = (subchartType === SignalType.Signal_Actigraphy && ((zoomedChartDomainMin - padding) < 0)) ? 0 : zoomedChartDomainMin - padding;
        zoomedChartDomainMax = (subchartType === SignalType.Signal_SPO2 && ((zoomedChartDomainMax + padding) > 100)) ? 100 : zoomedChartDomainMax + padding;
        zoomedChartDomainY.push(zoomedChartDomainMin, zoomedChartDomainMax);
        zoomedChartDomainX.push(zoomedChartData[0].secondsSince12pm, zoomedChartData[zoomedChartData.length - 1].secondsSince12pm);

        const startDateTime = new Date(this.state.selectedDateTime + zoomedChartData[0].secondsSince12pm * 1000);
        const endDateTime = new Date(this.state.selectedDateTime + zoomedChartData[zoomedChartData.length - 1].secondsSince12pm * 1000);
        
        return (
            <>
                <div className="modal-zoom-chart animate__animated animate__zoomIn animate__faster">
                    <h2 style={{color: colorPalette.signalType[getSignalTypeIdentifier(subchartType)]}}>{label}</h2>
                    <h3>
                        {formatFullTime(startDateTime)}
                        {' - '}
                        {formatFullTime(endDateTime)}
                    </h3>
                    <button className="modal-close-button" onClick={this.handleCloseModalZoomChart}>&times;</button>

                    <div>
                        <LineChart data={zoomedChartData} width={600} height={300}>
                            <CartesianGrid strokeDasharray="1 3" />

                            <XAxis
                                dataKey="secondsSince12pm"
                                type="number"
                                name="t"
                                domain={zoomedChartDomainX}
                                tick={(props) => <TimelineTick {...props} baseDate={new Date(this.state.selectedDateTime)} displaySeconds />}
                                interval="equidistantPreserveStartEnd"
                            />
                            <YAxis
                                domain={zoomedChartDomainY}
                                tick={(props) => <ChartAxisTickColored {...props} decimals={2} unit={unit} fill={colorPalette.signalTypeLight[getSignalTypeIdentifier(subchartType)]} />}
                                type="number"
                                tickCount={4}
                                tickFormatter={this.formatYAxis}
                                interval="equidistantPreserveStartEnd"
                            />

                            {zoomedChartMedicalEvents.map(block => {
                                if (block.fromSecondsSince12pm === block.toSecondsSince12pm) {
                                    return (
                                        <ReferenceLine
                                            key={`eventBlock-${subchartType}-${block.fromSecondsSince12pm}`}
                                            x={block.fromSecondsSince12pm + startTimestamp}
                                            stroke={colorPalette.signalType[getSignalTypeIdentifier(subchartType)]}
                                            strokeWidth={0.5}
                                        />
                                    );
                                }
                                else {
                                    return (
                                        <ReferenceArea
                                            key={block.fromSecondsSince12pm}
                                            x1={block.fromSecondsSince12pm}
                                            x2={block.toSecondsSince12pm}
                                            fill={colorPalette.signalType[getSignalTypeIdentifier(subchartType)]}
                                            strokeOpacity={0.0}
                                            fillOpacity={0.25}
                                        />
                                    );
                                }
                            })}

                            <Line
                                isAnimationActive={false}
                                type="linear"
                                dot={false}
                                activeDot={false}
                                dataKey="value"
                                stroke={colorPalette.clearColor}
                                strokeWidth={1.2}
                                connectNulls={false}
                            />
                            <Line
                                isAnimationActive={false}
                                type="linear"
                                dot={false}
                                activeDot={false}
                                dataKey="reliableValue.value"
                                stroke={colorPalette.signalType[getSignalTypeIdentifier(subchartType)]}
                                strokeWidth={1.5}
                                opacity={1}
                                connectNulls={false}
                            />

                            {eventConfiguration && subchartType !== SignalType.Signal_SPO2 && (
                                <ReferenceLine
                                    y={eventConfiguration.high.value}
                                    label={{
                                        position: (eventConfiguration.high.value >= (zoomedChartDomainY[1] - (5))) ? 'bottom' : 'top',
                                        value: `${eventConfiguration.high.value}${unit}`,
                                        fill: colorPalette.signalTypeClear[getSignalTypeIdentifier(subchartType)]
                                    }}
                                    stroke={colorPalette.signalTypeClear[getSignalTypeIdentifier(subchartType)]}
                                    strokeDasharray="4"
                                />
                            )}
                            {eventConfiguration && (
                                <ReferenceLine
                                    y={eventConfiguration.low.value}
                                    label={{
                                        position: (eventConfiguration.low.value <= (zoomedChartDomainY[0] + (5))) ? 'top' : 'bottom',
                                        value: `${eventConfiguration.low.value}${unit}`,
                                        fill: colorPalette.signalTypeClear[getSignalTypeIdentifier(subchartType)]
                                    }}
                                    stroke={colorPalette.signalTypeClear[getSignalTypeIdentifier(subchartType)]}
                                    strokeDasharray="4"
                                />
                            )}

                            {tooltip && (
                                <Tooltip content={tooltip} isAnimationActive={false} />
                            )}
                        </LineChart>
                    </div>
                </div>
                <div className="modal-overlay" onClick={this.handleCloseModalZoomChart} />
            </>
        );
    }

    formatYAxis(tickItem) {
        if (tickItem === Math.floor(tickItem)) {
            return tickItem;
        }
        else {
            return tickItem.toFixed(2);
        }
    }

    handleDocumentMouseOver() {
        this.setState(() => ({
            tooltipData: {}
        }));
    }

    handleCloseModalZoomChart() {
        this.setState(() => ({
            displayModalZoom: false,
            zoomAreaStartTimestamp: null,
            zoomAreaStartCoordinate: {x: 0, y: 0},
            zoomAreaEndTimestamp: null,
            zoomAreaEndCoordinate: {x: 0, y: 0},
            zoomSelectedSubchart: null,
        }));
    }

    renderMetricChart({width, height}) {
        const props = this.props;
        const state = this.state;

        const subcharts = Object.entries(this.state.subcharts).filter(e => !!e[1]).map(e => parseInt(e[0]));
        const selectedTimeLength = 24*3600;

        const chartWidth = TimelineChart.yaxisWidth + (selectedTimeLength * PatientTimelinePage.zoomLevel.value);
        const chartAreaWidth = width - TimelineChart.yaxisWidth;
        
        const patient = props.patient;

        if (state.chartAreaWidth !== chartAreaWidth) {
            setTimeout(() => {
                this.setState(state => ({
                    ...state,
                    chartAreaWidth: chartAreaWidth,
                }));
            }, 50);
        }

        if (state.fetchingSignalsAndEvents) {
            return <LoadingView/>;
        }
        if (!this.checkSignalsPresence()) {
            return (
                <div className="no-data-message-block">
                    <div className="info-message">No recording for this day.</div>
                    <img src={babySittingSkinType[patient.skinType]} alt="Baby sitting" width="150" />
                </div>
            );
        }

        const visibleEndTime = state.visibleStartTime + state.chartAreaWidth / PatientTimelinePage.zoomLevel.value;
        const visibleWidth = Number((100*(visibleEndTime-state.visibleStartTime)/selectedTimeLength).toFixed(1));
        const scrollBarCursorWidth = (visibleWidth < 100) ? visibleWidth : 100;

        return (
            <TimelineChart
                baseDate={new Date(this.state.selectedDateTime)}
                endTime={24*3600}
                eventConfiguration={props.eventConfiguration}
                height={height}
                id_patient={props.params.id_patient}
                medicalEvents={state.medicalEvents}
                ranges={state.ranges}
                scrollBarCursorWidth={scrollBarCursorWidth}
                scrollX={state.visibleStartTime / selectedTimeLength}
                signals={state.signals}
                startTime={0}
                subcharts={subcharts}
                width={chartWidth}
                //onScroll={this.handleChartScroll}
                onZoomChart={this.handleZoomChart}
                onDisplayTooltip={this.handleDisplayTooltip}
            />
        );
    }

    handleDisplayTooltip(tooltipData) {
        this.setState(() => ({
            tooltipData: tooltipData,
        }));
    }

    handleZoomChart(zoomData) {
        if (zoomData.zoomAreaEndTimestamp === zoomData.zoomAreaStartTimestamp) {
            return;
        }
        this.setState(() => ({
            displayModalZoom: true,
            zoomData: zoomData,
        }));
    }

    handleSelectDate(selectedDate){
        let url = `/patients/${this.props.params.id_patient}/timeline/${formatDateForUrl(selectedDate)}`;
        this.props.navigate(url);

        this.setState(state => ({
            ...state,
            selectedDateTime: atNoon(selectedDate).getTime(),
            visibleStartTime: 0,
        }), () => {
            // noinspection JSIgnoredPromiseFromCall
            this.fetchSignalsAndEvents();
        });
    }
    
    handleChartScroll(deltaTime) {
        const state = this.state;
    
        const visibleTimeLength = state.chartAreaWidth / PatientTimelinePage.zoomLevel.value;
        const actualDeltaTime = Math.min(
            24*3600 - visibleTimeLength,
            Math.max(
                0, state.visibleStartTime + deltaTime
            )
        ) - state.visibleStartTime;
        
        this.setState(state => ({
            ...state,
            visibleStartTime: state.visibleStartTime + actualDeltaTime,
        }));
    }

    handleChartScrollClick(cursorPositionTime) {
        this.setState(state => ({
            ...state,
            visibleStartTime: cursorPositionTime,
        }));
    }

    // handleEventConfigurationChanged(selectedEventConfiguration) {
    //     this.setState(state => ({
    //         ...state,
    //         eventConfiguration: selectedEventConfiguration
    //     }), () => {
    //         // noinspection JSIgnoredPromiseFromCall
    //         this.fetchSignalsAndEvents();
    //     });
    // }
}

//language=SCSS
PatientTimelinePage = Styled(PatientTimelinePage)`
    & {
        svg, svg * {
            user-drag: none;
        }
        
        .timeline-content-wrapper {
            display: flex;
        }
        
        .timeline-content {
            padding: 30px;
            width: calc(100% - 250px);
            height: calc(100vh - 70px);
            position: absolute !important;
            left: 250px;
            overflow-x: hidden;
        }
        
        .timeline-zoom-slider {
            padding: 0 50px 0 50px;
            width: 100%;
            margin-bottom: 15px;
            
            .rc-slider-mark {
                margin-top: -4px;
            }
            
            .rc-slider-mark-text {
                color: ${colorPalette.frontColor};
            }
        }
        
        .timeline-charts {
            width: 100%;
            margin-top: 45px;
        }
        
        .timeline__chart-section {
            padding: 0 30px;
            height: 100%;
        }
        
        .rc-slider-tooltip-inner {
            white-space: nowrap;
        }
        
        .timeline__eventsChart .info-message {
            margin-top: 20px;
        }
        
        .modal-zoom-chart {
            position: absolute;
            width: auto;
            height: auto;
            top: 200px;
            left: calc(50% - 350px);
            background: ${colorPalette.mainBackground};
            padding: 20px 30px;
            border-radius: 5px;
            z-index: 1050;
            
            h2 {
                font-size: 15px;
                margin: 15px 0 5px 0;
            }
            
            h3 {
                font-size: 14px;
                margin: 5px 0 25px 0;
                color: ${colorPalette.clearColor}
            }
            
            .modal-close-button {
                position: absolute;
                right: 10px;
                top: 10px;
                border: none;
                font-size: 20px;
                color: ${colorPalette.frontColor};
                background: transparent;
            }
            
            .recharts-cartesian-axis line,
            .recharts-cartesian-grid line {
                stroke: ${colorPalette.clearColor};
            }
            
            .recharts-cartesian-axis-ticks {
                tspan {
                    fill: #fff;
                }
            }
            
            .recharts-rectangle.recharts-tooltip-cursor,
            .recharts-curve.recharts-tooltip-cursor {
                fill: ${colorPalette.activeColor};
                stroke: ${colorPalette.activeColor};
            }
        }
        
        .modal-overlay {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: ${colorPalette.thirdBackground};
            opacity: 0.8;
            z-index: 1000;
        }
        
        .no-access-message-block {
            width: 600px;
            margin: 70px auto 0 auto;
            
            img {
                display: block;
                margin: 20px auto 0 auto;
            }
        }
        
        .no-data-message-block {
            width: 600px;
            margin: 0 auto;
            
            img {
                display: block;
                margin: 20px auto 0 auto;
            }
        }
    }
`;

PatientTimelinePage = withRouter(
    withTutorialStatus(withDaysWithData(withEventConfig(withPatientData(
        wrapWithPage(PatientTimelinePage)
    ))))
);

export {PatientTimelinePage};
