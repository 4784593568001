import PropTypes from 'prop-types';
import styled from 'styled-components';

import HealthReportNavigation from '@/components/business/analytics/health-report/health-report-navigation';
import {HealthReportSidebar} from '@/components/business/analytics/health-report/health-report-sidebar';

const HealthReportWrapper = ({patientId, patientData, activeDashboard, eventConfiguration, onChanged, children}) => {
    return (
        <StyledWrapper className="health-report-wrapper">
            <HealthReportSidebar
                patient={patientData}
                eventConfiguration={eventConfiguration}
                onEventConfigurationChanged={selectedEventConfiguration => onChanged(selectedEventConfiguration)}
            />

            <div className="health-report-widgets-wrapper">
                <div className="health-report-header">
                    <HealthReportNavigation
                        activeDashboard={activeDashboard}
                        patientId={patientId}
                    />
                </div>

                {children}
            </div>
        </StyledWrapper>
    );
};

HealthReportWrapper.propTypes = {
    patientId: PropTypes.string.isRequired,
    patientData: PropTypes.object,
    activeDashboard: PropTypes.number.isRequired,
    eventConfiguration: PropTypes.object,
    onChanged: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
};

//language=SCSS
const StyledWrapper = styled.div`
& {
    .health-report-widgets-wrapper {
        padding: 30px;
        padding-bottom: 160px;
        width: calc(100% - 250px);
        overflow-y: auto;
        position: absolute !important;
        left: 250px;
        z-index: -500;
    }
    
    .health-report-header {
        display: flex;
        position: relative;
    }
}
`;

export {HealthReportWrapper};
