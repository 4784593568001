import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import Styled from 'styled-components';

import PatientOnboardingPrint from '@/components/business/analytics/patient/patient-update/patient-update-onboarding-print.jsx';
import {Button} from '@/components/buttons/button.jsx';
import {Modal} from '@/components/layout/modal';
import LoadingView from '@/components/static/loading-view.tsx';
import {AlertTypeEnum} from '@/enum/alert-type-enum.jsx';
import {BackendApiService} from '@/services/backend-api-service.js';
import {FeedbackAlertsService} from '@/services/feedback-alerts-service.js';
import {colorPalette} from '@/themes/darkmode.js';

class PatientUpdateOnboarding extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        patientId: PropTypes.string.isRequired,
        showModalOnboarding: PropTypes.bool.isRequired,
        onClosed: PropTypes.func.isRequired,
    };

    state = {
        loading: false,
        showModalOnboarding: this.props.showModalOnboarding,
        showModalGenerateConfirmation: false,
        generateConfirm: '',
        qrCode: null,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        this.getLatestQrCode();
    }

    isFilled() {
        const state = this.state;

        return (
            state.generateConfirm && state.generateConfirm === 'GENERATE'
        );
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                {state.showModalGenerateConfirmation &&
                <Modal title={t(['Onboarding', 'patientOnboarding.title'])} onClosed={this.handleCloseModal}>
                    <form onSubmit={this.handleGenerateQrCodeConfirmation}>
                        <div className="fieldWrapper">
                            <p>
                                {t(['Are you sure you want to generate a new QR code?', 'patientOnboarding.generate.firstLine'])}<br />
                                {t(['This action will destroy existing QR code.', 'patientOnboarding.generate.secondLine'])}
                            </p>
                        </div>

                        <div className="fieldWrapper">
                            <label htmlFor="generate-confirm" className={`mandatoryField ${(this.state.generateConfirm === '') ? 'empty' : 'filled'}`}>{t(['Write GENERATE in the field below', 'patientOnboarding.generate.generateConfirm'])}</label>
                            <input name="generate-confirm" id="generate-confirm" required autoFocus aria-required="true" value={this.state.generateConfirm} onChange={this.handleChangeGenerateConfirm} />
                        </div>
                        <div>
                            <Button className="button-delete" disabled={!this.isFilled()} loading={state.loading}>{t(['Generate new QR code', 'patientOnboarding.generate.confirm'])}</Button>
                            <Button className="button-cancel" onClick={this.handleCloseModal}>{t(['Cancel', 'patientOnboarding.generate.cancel'])}</Button>
                        </div>
                    </form>
                </Modal>
                }

                {state.showModalOnboarding &&
                <Modal title={t(['Onboarding', 'patientOnboarding.title'])} onClosed={this.handleCloseModal}>
                    <div className="qrCodeWrapper">
                        <div className="qrCodeContent">
                            {!state.loading && state.qrCode &&
                                <img
                                    src={`data:image/jpeg;base64,${state.qrCode.qrCodeData}`}
                                    alt={t(['Patient QR Code', 'patientOnboarding.qrCodeAlt'])}
                                />
                            }
                            {state.loading && <LoadingView />}
                        </div>

                        <div>
                            <p>{t(['The caregiver needs to scan this QR code in the Gabi Monitor Application during the onboarding process.', 'patientOnboarding.scan'])}</p>

                            <ReactToPrint content={() => this.componentRef}>
                                <PrintContextConsumer>
                                    {({ handlePrint }) => (
                                        <Button className="button-active" onClick={handlePrint}>{t(['Print QR code', 'patientOnboarding.qrCodePrint'])}</Button>
                                    )}
                                </PrintContextConsumer>
                            </ReactToPrint>
                        </div>
                    </div>

                    <hr />

                    <div className="generateWrapper">
                        <Button className="button-delete" loading={state.loading} onClick={this.handleGenerateQrCode}>{t(['Generate new QR code', 'patientOnboarding.generate.confirm'])}</Button>
                    </div>

                    <div className="hidden">
                        <PatientOnboardingPrint patientId={props.patientId} qrCode={state.qrCode} ref={el => (this.componentRef = el)} />
                    </div>
                </Modal>
                }
            </div>
        );
    }

    handleChangeGenerateConfirm(e) {
        this.setState(state => ({
            ...state,
            generateConfirm: e.target.value,
        }));
    }

    handleCloseModal() {
        this.setState(state => ({
            ...state,
            showModalConfirm: false,
            showModalOnboarding: false,
        }));

        if(this.props.onClosed) {
            this.props.onClosed();
        }
    }

    handleGenerateQrCode() {
        this.setState(state => ({
            ...state,
            showModalGenerateConfirmation: true,
            showModalOnboarding: false,
        }));
    }

    handleGenerateQrCodeConfirmation(e) {
        e.preventDefault();
        this.generateQrCode();
    }

    getLatestQrCode() {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'patient',
            modelName: 'getLatestQrCode',
            data: {
                id: this.props.patientId
            }
        }).then((response) => {
            this.setState(state => ({
                ...state,
                loading: false,
                qrCode: response,
            }));
            return response;
        }).catch(err => {
            console.error(err);

            this.setState(state => ({
                ...state,
                loading: false,
            }));
        });
    }

    generateQrCode() {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'patient',
            modelName: 'generatePatientQrCode',
            data: {
                id: this.props.patientId,
            }
        }).then((response) => {
            this.setState(state => ({
                ...state,
                loading: false,
                qrCode: response,
                showModalGenerateConfirmation: false,
                showModalOnboarding: true,
                generateConfirm: ''
            }));

            FeedbackAlertsService.add(AlertTypeEnum.SUCCESS, 'generatePatientQrCode', this.props.t(['A new QR code has been generated!', 'patientOnboarding.generate.success']));

            return response;
        }).catch(err => {
            console.error(err);

            this.setState(state => ({
                ...state,
                loading: false,
            }));
        });
    }
}

//language=SCSS
PatientUpdateOnboarding = Styled(PatientUpdateOnboarding)`
& {
    .qrCodeWrapper {
      width: 500px;
      display: flex;
      padding: 20px 0;

      > p {
        text-align: left;
      }

      .qrCodeContent {
        border: 1px solid ${colorPalette.activeColor};
        padding: 10px;
        width: 250px;
        margin: 0 20px 0 0;

        img {
          display: block;
        }
      }
    }
    .generateWrapper {
        padding: 10px 0;
    }
}
`;

export default withTranslation()(PatientUpdateOnboarding);

