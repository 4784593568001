import {subDays} from 'date-fns';
import {PatientQueryServiceClient} from 'gabi-api-ts/v2/patient/query/patient_query.client';
import {SignalType} from 'gabi-api-ts/v2/signal/query/signal_query';
import {SignalQueryServiceClient} from 'gabi-api-ts/v2/signal/query/signal_query.client';
import {floorHour} from 'gabi-web-common/util/date-util';
import PropTypes from 'prop-types';
import {getI18n} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import Styled from 'styled-components';

import HealthReportNoRecording from '@/components/business/analytics/health-report/health-report-no-recording';
import {HealthReportStatisticsPerDay} from '@/components/business/analytics/health-report/health-report-statistics-per-day';
import {HealthReportWrapper} from '@/components/business/analytics/health-report/health-report-wrapper';
import ColoredContainer from '@/components/layout/colored-container';
import LoadingView from '@/components/static/loading-view';
import {Page} from '@/decorators/page';
import withRouter from '@/decorators/withRouter';
import {HealthReportDashboardTypeEnum} from '@/enum/health-report-dashboard-type-enum';
import {useBackendQuery} from '@/hooks/use-backend-query';
import {formatApiDateToJSDate} from '@/services/api-requests/requests-utils';
import {colorPalette} from '@/themes/darkmode';
import {NewDateUtil} from '@/util/new-date-util';
import {noop} from '@/util/noop';
import {formatDateForUrl} from '@/util/url-util';

function getPagePath() {
    return [
        {
            route: 'patients',
            name: 'Patients'
        },
    ];
}

function wrapWithPage(Component) {
    return Page({
        name: () => getI18n().t(['Health Report - trends 90 nights', 'healthReport.trends.title']),
        pagePath: props => (
            getPagePath(props)
        )
    })(Component);
}

const HealthReportTrendsPageProptypes = {
    params: PropTypes.object,
    className: PropTypes.string,
};

function HealthReportTrendsPageUnstyled(props) {
    const navigate = useNavigate();
    const patientId = props.params.id_patient;
    
    const [patientDataLoading, patientData] = useBackendQuery({
        serviceClient: PatientQueryServiceClient,
        query: PatientQueryServiceClient.prototype.get,
        memoize: true,
        data: { id: patientId },
    });
    
    const [signalsEventConfigurationLoading, signalsEventConfiguration, /*err*/, refresh] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getEventConfiguration,
        memoize: true,
        data: { patientId: { id: patientId } },
    });
    
    const loading = (
        (patientDataLoading || !patientData) ||
        (signalsEventConfigurationLoading || !signalsEventConfiguration)
    );

    if (loading) {
        return (
            <HealthReportWrapper
                patientId={patientId}
                patientData={patientData}
                activeDashboard={HealthReportDashboardTypeEnum.TRENDS}
                eventConfiguration={signalsEventConfiguration}
                onChanged={noop}
            >
                <LoadingView />
            </HealthReportWrapper>
        );
    }
    
    let dateFrom = null;
    let dateTo = null;
    let dashboardHasContent = false;
    let lastRecordingDate = null;
    if (patientData.lastRecordingDate) {
        dateFrom = floorHour(subDays(NewDateUtil(), 89));
        dateTo = floorHour(NewDateUtil());
        
        lastRecordingDate = formatApiDateToJSDate(patientData.lastRecordingDate);

        dashboardHasContent = lastRecordingDate.getTime() > dateFrom.getTime();
    }
    
    const handleWidgetChartClick = (selectedDate) => {
        let url = `/patients/${props.params.id_patient}/timeline/${formatDateForUrl(selectedDate)}`;
        navigate(url);
    };
    
    return (
        <div className={`health-report-trends ${props.className ?? ''}`}>
            <HealthReportWrapper
                patientId={patientId}
                patientData={patientData}
                activeDashboard={HealthReportDashboardTypeEnum.TRENDS}
                eventConfiguration={signalsEventConfiguration}
                onChanged={refresh}
            >
                {!dashboardHasContent ? (
                    <HealthReportNoRecording patient={patientData} activeDashboard={HealthReportDashboardTypeEnum.TRENDS} />
                ) : (
                    <div className={'health-report-content'}>
                        <div className="health-report-widgets">
                            <ColoredContainer className="widget-average" color={colorPalette.signalType.pulseRate}>
                                <HealthReportStatisticsPerDay
                                    patientId={patientId}
                                    signalType={SignalType.Signal_PR}
                                    lastRecordingDate={lastRecordingDate}
                                    dateFrom={dateFrom}
                                    dateTo={dateTo}
                                    signalEventConfiguration={signalsEventConfiguration.configuration.hr}
                                    displayPreviousNights={false}
                                    onClick={handleWidgetChartClick}
                                />
                            </ColoredContainer>
                            <ColoredContainer className="widget-average" color={colorPalette.signalType.spo2}>
                                <HealthReportStatisticsPerDay
                                    patientId={patientId}
                                    signalType={SignalType.Signal_SPO2}
                                    lastRecordingDate={lastRecordingDate}
                                    dateFrom={dateFrom}
                                    dateTo={dateTo}
                                    signalEventConfiguration={signalsEventConfiguration.configuration.spo2}
                                    displayPreviousNights={false}
                                    onClick={handleWidgetChartClick}
                                />
                            </ColoredContainer>
                        </div>
                    </div>
                )}
            </HealthReportWrapper>
        </div>
    );
}
HealthReportTrendsPageUnstyled.propTypes = HealthReportTrendsPageProptypes;

//language=SCSS
let HealthReportTrendsPage = Styled(HealthReportTrendsPageUnstyled)`
& {
    .health-report-content {
        display: flex;
        flex-wrap: nowrap;

        .health-report-widgets {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            width: 100%;
        }

        .widget-average {
            margin-bottom: 10px;
            position: relative;
        }
    }
}
`;

HealthReportTrendsPage = withRouter(wrapWithPage(HealthReportTrendsPage));

export {HealthReportTrendsPage};
