import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import PatientsTableRow from '@/components/business/analytics/patients/patients-table-row';
import {InfoMessage} from '@/components/form/info-message';
import {TableSortable} from '@/components/table/table-sortable';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import searchBabyNoResult from '@/public/images/search-baby-no-result.svg';

const PatientsSearchTable = ({patients}) => {
    const { t } = useTranslation();

    const tableSortableHeaderColumn = [
        {
            label: '',
            accessor: 'patientId.id',
            sortable: false
        },
        {
            label: t(['Patient', 'patient.title']),
            accessor: 'lastName',
            sortable: true
        },
        {
            label: t(['Last recording', 'patient.lastRecording']),
            accessor: 'lastRecordingDataTimeSortable',
            sortable: true
        },
        {
            label: t(['Caregiver', 'caregiver.title']),
            accessor: 'caregiver',
            sortable: false
        },
        {
            label: '',
            accessor: 'edit',
            sortable: false
        },
        {
            label: '',
            accessor: 'delete',
            sortable: false
        },
    ];

    if(patients) {
        if(patients.length > 0) {
            return (
                <TableSortable
                    data={patients}
                    columns={tableSortableHeaderColumn}
                    defaultSorting={{sortByOrder: 'desc', accessor: 'name'}}
                    TableBodyRowComponent={PatientsTableRow}
                />
            );
        } else {
            return (
                <StyledWrapperNoResult>
                    <img src={searchBabyNoResult} width={150} alt="baby fox" />
                    <InfoMessage infoMessage={t(['No results for this search.', 'pages.patients.searchPatientNoResult'])} />
                </StyledWrapperNoResult>
            );
        }
    } else {
        return <ComponentErrorMessage component="PatientsSearchTable" />;
    }
};

PatientsSearchTable.propTypes = {
    patients: PropTypes.array.isRequired,
};

//language=SCSS
const StyledWrapperNoResult = styled.div`
& {
    width: 400px;
    margin: 0 auto;
    text-align: center;
    
    img {
        margin-bottom: 20px;
    }
}
`;

export default PatientsSearchTable;
