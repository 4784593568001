import { v4 as uuid } from 'uuid';

import { model } from '@/store/simpler-redux';

export default model({
    name: 'alertBanners',
    state: [],
    reducers: {
        add: (state, data) => {
            data = Object.assign({}, data);
            if (!data.id) {
                data.id = uuid();
            }
            
            const newState = state.slice();
            newState.push(data);
            return newState;
        },
        
        remove: (state, data) => {
            return state.filter(item => {
                return item.id !== data.id;
            });
        },
    },
});
