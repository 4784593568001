import {Auth0Service} from '@/services/auth0-service';
import store from '@/store/index';
import { models } from '@/store/models/async/backend-api';
import { AsyncStatus } from '@/store/simpler-redux';
import {NewDateUtil} from '@/util/new-date-util';

import { BackendApiError } from './backend-api-error';

let transportService = null;
let componentTesting = false;

/**
 * This services handles the communication with the Backend API.
 * It will store the data in the redux store after the call.
 * The actual calls are not made in this service. It needs a transportService which handles them, whatever the protocol used
 */
const BackendApiService = {
    setTransportService(service) {
        transportService = service;
    },

    setComponentTesting(testing) {
        componentTesting = testing;
    },

    getRequestIdentifier(options) {
        const out = {};
        const keys = Object.keys(options);
        keys.sort();
        keys.forEach(key => {
            if (!(typeof(options[key]) === 'undefined' || options[key] === null)) {
                out[key] = options[key];
            }
        });
        delete out['cached'];
        delete out['shouldComponentReload'];
        delete out['allowError'];
        return JSON.stringify(out);
    },

    /**
     * Handler of any request.
     * @param options Options of the request:
     * - modelName: (required) name of the model to request
     * - method: (required) method to call : create | readAll | readOne | update | delete | action
     * - id: (only: readOne, update, delete, action) if provided, id of the entity in the model to request
     * - filter: (only: readAll) json-compatible object for the filter query
     * - payload: (only: create, update, action)
     * - ttl: (default 1hour; only: readAll, readOne) time to live in cache for future calls, in seconds. Set to 0 to disable cache
     * @return {Promise<*>} Whatever the transport service will return
     */
    getRequest(options) {
        const requestIdentifier = this.getRequestIdentifier(options);

        options = Object.assign({
            ttl: 3600,
            filter: null,
        }, options);

        StoreSubService.__prepareRequest(requestIdentifier, options);
        if(componentTesting) {
            return (
                transportService.getRequest('', options)
                    .then(data => {
                        StoreSubService.__setStoreResponse(requestIdentifier, data);
                        return data;
                    })
                    .catch(err => {
                        console.error(err);
                        StoreSubService.__setStoreError(requestIdentifier, errorHandler(err));
                        throw err;
                    })
            );
        }
        else {
            return (
                Auth0Service.getAccessToken()
                    .then(accessToken => transportService.getRequest(accessToken, options))
                    .then(data => {
                        StoreSubService.__setStoreResponse(requestIdentifier, data);
                        return data;
                    })
                    .catch(err => {
                        console.error(err);
                        StoreSubService.__setStoreError(requestIdentifier, errorHandler(err));
                        throw err;
                    })
            );
        }
        
    },

    /**
     * Handler of any request.
     * @param options Options of the request:
     * - modelName: (required) name of the model to request
     * - method: (required) method to call : create | readAll | readOne | update | delete | action
     * - id: (only: readOne, update, delete, action) if provided, id of the entity in the model to request
     * - filter: (only: readAll) json-compatible object for the filter query
     * - payload: (only: create, update, action)
     * - cached: (default true; only: readAll, readOne) caches the result of the request for future use. If false, forces the request to be executed again
     * - ttl: (default 1hour; only: readAll, readOne) time to live in cache for future calls, in seconds. Set to 0 to disable cache
     * @return {Promise<*>} Whatever the transport service will return
     */
    getRequestWithoutToken(options) {
        const requestIdentifier = this.getRequestIdentifier(options);

        options = Object.assign({
            ttl: 3600,
            filter: null,
        }, options);

        StoreSubService.__prepareRequest(requestIdentifier, options);
        return (
            transportService.getRequest('', options)
                .then(data => {
                    StoreSubService.__setStoreResponse(requestIdentifier, data);
                    return data;
                })
                .catch(err => {
                    console.error(err);
                    StoreSubService.__setStoreError(requestIdentifier, errorHandler(err));
                    throw err;
                })
        );
    },

    /**
     * Reads all elements of a model matching a filter (all if no filter provided)
     * @param modelName the name of the model to read
     * @param domainName the domain where the modelName is located
     * @param filter json-compatible object for the filter query
     * @param options Options of the request:
     * - cached: (default true) caches the query for future use. If false, forces the query to be executed again
     * - ttl: (default 1hour) time to live in cache for future calls, in seconds. Set to 0 to disable cache
     * @return {Promise<*>} Whatever the transport service will return
     */
    fetchAll(modelName, domainName, filter, options) {
        return (
            this.getRequest({
                ...options,
                domain: domainName,
                modelName: modelName,
                method: 'readAll',
            })
        );
    },

    /**
     * Reads one element of a model, identified by its id.
     * @param modelName the name of the model to read
     * @param domainName the domain where the modelName is located
     * @param id id of the entity to read
     * @param options Options of the request:
     * - cached: (default true) caches the query for future use. If false, forces the query to be executed again
     * - ttl: (default 1hour) time to live in cache for future calls, in seconds. Set to 0 to disable cache
     * @return {Promise<*>} Whatever the transport service will return
     */
    fetchOne(modelName, domainName, id, options) {
        return (
            this.getRequest(Object.assign({
                modelName: modelName,
                domain: domainName,
                method: 'readOne',
                id: id,
            }, options))
        );
    },
};

const StoreSubService = {
    __prepareRequest(requestIdentifier, options) {
        store.dispatch(models['backend-api'].actions.prepare({
            request: requestIdentifier,
            status: AsyncStatus.FETCHING,
            ttl: options.ttl,
            date: NewDateUtil(),
        }));
    },

    __getStoreEntry(requestIdentifier) {
        return store.getState()['backend-api'][requestIdentifier];
    },

    __setStoreResponse(requestIdentifier, data) {
        const model = models['backend-api'];
        //console.debug('SET ALL', requestIdentifier);
        //data = arrayToMap(data, item => item.id);

        store.dispatch(model.actions.update({
            request: requestIdentifier,
            status: AsyncStatus.FETCHED,
            data: data,
        }));
    },

    __setStoreError(request, error) {
        const model = models['backend-api'];
        console.error('ERR', request, error);
        store.dispatch(model.actions.update({
            request: request,
            status: AsyncStatus.ERROR,
            error: error,
            data: null,
        }));
    },

    /*__removeStoreEntry(request) {
        const model = models['backend-api'];
        store.dispatch(model.actions.remove({
            request: request,
        }));
    },*/
};

function errorHandler(err) {
    if (!(err instanceof BackendApiError)) {
        let status = err.status || -1;
        let message = err.message || 'No error message';
        let data = {};
    
        //Got response
        if (err.response) {
            status = err.response.status || status;
            data = err.response.data || data;
        }
        return new BackendApiError(status, message, data);
    }
    else {
        return err;
    }
}

window.BackendApiService = BackendApiService;

export { BackendApiService };
//----------------------------------------------------------------------------------------------------------------------
