import { v4 as uuid } from 'uuid';

import config from '@/config';
import {AlertTypeEnum} from '@/enum/alert-type-enum.jsx';
import store from '@/store/index.ts';
import { feedbackAlerts } from '@/store/models';
const messageLifetime = config.feedbackAlerts.lifetime * 1000;

const FeedbackAlertsService = {
    add(type, apiFunction, message, code) {
        const alert = {
            type: type,
            apiFunction: apiFunction,
            message: message,
            code: code, // Optional
            id: uuid(),
        };

        store.dispatch(feedbackAlerts.actions.add(alert));

        if(alert.type === AlertTypeEnum.SUCCESS) {
            setTimeout(() => {
                store.dispatch(feedbackAlerts.actions.remove(alert));
            }, messageLifetime);
        }
    },
    
    remove(alert) {
        store.dispatch(feedbackAlerts.actions.remove(alert));
    },
};

export { FeedbackAlertsService };
