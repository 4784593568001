import {subDays} from 'date-fns';
import {PatientQueryServiceClient} from 'gabi-api-ts/v2/patient/query/patient_query.client';
import {AlertType, SignalType} from 'gabi-api-ts/v2/signal/query/signal_query';
import {SignalQueryServiceClient} from 'gabi-api-ts/v2/signal/query/signal_query.client';
import {floorHour} from 'gabi-web-common/util/date-util';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {getI18n, withTranslation} from 'react-i18next';
import Styled from 'styled-components';

import HealthReportDuration from '@/components/business/analytics/health-report/health-report-duration';
import {HealthReportEventsPerDay} from '@/components/business/analytics/health-report/health-report-events-per-day';
import HealthReportNoRecording from '@/components/business/analytics/health-report/health-report-no-recording';
import {HealthReportPercentagePerDay} from '@/components/business/analytics/health-report/health-report-percentage-per-day';
import {HealthReportStatisticsPerDay} from '@/components/business/analytics/health-report/health-report-statistics-per-day';
import {HealthReportWrapper} from '@/components/business/analytics/health-report/health-report-wrapper';
import ColoredContainer from '@/components/layout/colored-container';
import LoadingView from '@/components/static/loading-view';
import {Page} from '@/decorators/page';
import {withBackendQuery} from '@/decorators/with-backend-query';
import withRouter from '@/decorators/withRouter';
import {HealthReportDashboardTypeEnum} from '@/enum/health-report-dashboard-type-enum';
import {formatApiDateToJSDate} from '@/services/api-requests/requests-utils';
import {colorPalette} from '@/themes/darkmode';
import {NewDateUtil} from '@/util/new-date-util';
import {formatDateForUrl} from '@/util/url-util';

function getPagePath() {
    return [
        {
            route: 'patients',
            name: 'Patients'
        },
    ];
}

function wrapWithPage(Component) {
    return Page({
        name: props => getI18n().t('healthReport.lastNnights.title', {N: props.nbDays}),
        pagePath: props => (
            getPagePath(props)
        ),
    })(Component);
}

function withEventConfig(Component) {
    return withBackendQuery('signalsEventConfigurationLoading', 'signalsEventConfiguration', 'signalsEventConfigurationError', props => ({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getEventConfiguration,
        memoize: true,
        data: { patientId: { id: props.params.id_patient } },
    }))(Component);
}

function withPatientData(Component) {
    return withBackendQuery('patientDataLoading', 'patientData', 'patientDataError', props => ({
        serviceClient: PatientQueryServiceClient,
        query: PatientQueryServiceClient.prototype.get,
        memoize: true,
        data: { id: props.params.id_patient },
    }))(Component);
}

function getHealthReportDashboardTypeEnum(/* number */ nbDays) {
    if (nbDays === 7) {
        return HealthReportDashboardTypeEnum.LAST_7DAYS;
    }
    else if (nbDays === 30) {
        return HealthReportDashboardTypeEnum.LAST_30DAYS;
    }
    else {
        throw new Error(`Invald nbDays: ${nbDays}`);
    }
}

class HealthReportLastNDaysPage extends React.Component {
    static propTypes = {
        nbDays: PropTypes.number.isRequired,
        signalsEventConfiguration: PropTypes.object,
        signalsEventConfigurationLoading: PropTypes.bool.isRequired,
        patientData: PropTypes.object,
        patientDataLoading: PropTypes.bool.isRequired,
        t: PropTypes.func,
        params: PropTypes.object,
        className: PropTypes.string,
        navigate: PropTypes.func,
    };

    state = {
        eventConfiguration: this.props.signalsEventConfiguration,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const props = this.props;
        const t = props.t;
        const patientId = props.params.id_patient;
        const eventConfiguration = props.signalsEventConfiguration;
        const patientData = props.patientData;

        const loading = (
            (props.signalsEventConfigurationLoading || !eventConfiguration) ||
            (props.patientDataLoading || !patientData)
        );
        
        const dashboardTypeEnum = getHealthReportDashboardTypeEnum(props.nbDays);
        
        if (loading) {
            return (
                <HealthReportWrapper
                    patientId={patientId}
                    patientData={patientData}
                    activeDashboard={dashboardTypeEnum}
                    eventConfiguration={eventConfiguration}
                    onChanged={this.handleEventConfigurationChanged}
                >
                    <LoadingView />
                </HealthReportWrapper>
            );
        }
        
        let dateFrom = null;
        let dateTo = null;
        let dashboardHasContent = false;
        let lastRecordingDate = null;
        if (props.patientData.lastRecordingDate) {
            dateFrom = floorHour(subDays(NewDateUtil(), props.nbDays-1));
            dateTo = floorHour(NewDateUtil());

            lastRecordingDate = formatApiDateToJSDate(props.patientData.lastRecordingDate);

            dashboardHasContent = lastRecordingDate.getTime() > dateFrom.getTime();
        }
        
        return (
            <div className={props.className}>
                <HealthReportWrapper
                    patientId={patientId}
                    patientData={patientData}
                    activeDashboard={dashboardTypeEnum}
                    eventConfiguration={eventConfiguration}
                    onChanged={this.handleEventConfigurationChanged}
                >
                
                    {dashboardHasContent &&
                        <div className="health-report-switch-wrapper">
                            <HealthReportDuration dateFrom={dateFrom} dateTo={dateTo} patientId={patientId}/>
                        </div>
                    }
                
                    {dashboardHasContent &&
                        <div className={'health-report-content health-report-content-view'}>
                            <div className="health-report-widgets">
                                <ColoredContainer className="widget-average" color={colorPalette.signalType.pulseRate}>
                                    <HealthReportStatisticsPerDay
                                        patientId={patientId}
                                        signalType={SignalType.Signal_PR}
                                        lastRecordingDate={lastRecordingDate}
                                        dateFrom={dateFrom}
                                        dateTo={dateTo}
                                        signalEventConfiguration={eventConfiguration.configuration.hr}
                                        displayPreviousNights={false}
                                        onClick={this.handleWidgetChartClick}
                                    />
                                </ColoredContainer>
                                
                                <ColoredContainer className="widget-events" color={colorPalette.signalType.pulseRate}>
                                    <HealthReportEventsPerDay
                                        patientId={patientId}
                                        signalType={SignalType.Signal_PR}
                                        alertTypes={[AlertType.Alert_LOW, AlertType.Alert_HIGH]}
                                        dateFrom={dateFrom}
                                        dateTo={dateTo}
                                        subtitle={t('healthReport.events.last24h.subtitle', {duration: eventConfiguration.configuration.hr.sensibility.seconds})}
                                        signalEventConfiguration={eventConfiguration.configuration.hr}
                                        onClick={this.handleWidgetChartClick}
                                    />
                                </ColoredContainer>
                
                                <ColoredContainer className="widget-average" color={colorPalette.signalType.spo2}>
                                    <HealthReportStatisticsPerDay
                                        patientId={patientId}
                                        signalType={SignalType.Signal_SPO2}
                                        lastRecordingDate={lastRecordingDate}
                                        dateFrom={dateFrom}
                                        dateTo={dateTo}
                                        displayPreviousNights={false}
                                        onClick={this.handleWidgetChartClick}
                                    />
                                </ColoredContainer>
                
                                <ColoredContainer className="widget-events" color={colorPalette.signalType.spo2}>
                                    <HealthReportPercentagePerDay
                                        patientId={patientId}
                                        signalType={SignalType.Signal_SPO2}
                                        alertType={AlertType.Alert_LOW}
                                        lastRecordingDate={lastRecordingDate}
                                        dateFrom={dateFrom}
                                        dateTo={dateTo}
                                        onClick={this.handleWidgetChartClick}
                                        displayPreviousNights={false}
                                    />
                                </ColoredContainer>
                            </div>
                        </div>
                    }
                    {!dashboardHasContent &&
                        <HealthReportNoRecording patient={props.patientData} activeDashboard={dashboardTypeEnum} />
                    }
                </HealthReportWrapper>
            </div>
        );
    }

    handleWidgetChartClick(selectedDate) {
        let url = `/patients/${this.props.params.id_patient}/timeline/${formatDateForUrl(selectedDate)}`;
        this.props.navigate(url);
    }

    handleEventConfigurationChanged(selectedEventConfiguration) {
        this.setState(state => ({
            ...state,
            eventConfiguration: selectedEventConfiguration,
        }));
    }
}

//language=SCSS
HealthReportLastNDaysPage = Styled(HealthReportLastNDaysPage)`
& {
    .health-report-content {
        display: flex;
        flex-wrap: nowrap;
    }

    .health-report-widgets {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        width: 100%;
        margin-top: 20px;
        gap: 10px;
    }
    
    .widget-events {
        position: relative;
        min-height: 340px;
    }

    .widget-average {
        position: relative;
        min-height: 340px;
    }
}
`;

HealthReportLastNDaysPage = withRouter(withEventConfig(withPatientData(wrapWithPage(withTranslation()(HealthReportLastNDaysPage)))));

export {HealthReportLastNDaysPage};
