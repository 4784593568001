import deepEqual from 'deep-eql';
import {EventConfiguration} from 'gabi-api-ts/v2/signal/common/signal_common';
import {SignalType} from 'gabi-api-ts/v2/signal/query/signal_query';
import {SignalQueryServiceClient} from 'gabi-api-ts/v2/signal/query/signal_query.client';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import EventSensibilityConfigurationSlider from '@/components/business/analytics/common/event-sensibility-configuration-slider';
import EventThresholdConfigurationSlider from '@/components/business/analytics/common/event-threshold-configuration-slider';
import {Button} from '@/components/buttons/button';
import InfoTooltip from '@/components/buttons/info-tooltip';
import {InfoMessage} from '@/components/form/info-message';
import {FlexChild, FlexLayout} from '@/components/layout/flex-layout';
import LoadingView from '@/components/static/loading-view';
import {AlertTypeEnum} from '@/enum/alert-type-enum';
import {useBackendQuery} from '@/hooks/use-backend-query';
import sliderIcon from '@/public/icons/slider-icon.svg';
import {BackendApiRefreshListeners} from '@/services/backend-api-refresh-listeners';
import {BackendApiService} from '@/services/backend-api-service';
import {FeedbackAlertsService} from '@/services/feedback-alerts-service';

type EventConfigurationFormProps = {
    className?: string;
    patientId: string;
    readonly?: boolean;
    onChanged?: (dataChanged: boolean) => void;
    onSaved?: () => void;
    onCancel?: () => void;
};

type SelectedEventConfiguration = {
    hrLowValue: number;
    hrHighValue: number;
    hrSensibility: number;
    spo2LowValue: number;
    spo2HighValue: number;
    spo2Sensibility: number;
    rrLowValue: number;
    rrHighValue: number;
    rrSensibility: number;
};

function convertEventConfiguration(eventConfiguration: EventConfiguration | null): SelectedEventConfiguration {
    const configuration = eventConfiguration?.configuration;
    return {
        hrLowValue: configuration?.hr?.low?.value ?? 0,
        hrHighValue: configuration?.hr?.high?.value ?? 200,
        hrSensibility: configuration?.hr?.sensibility?.seconds ?? 5,
        spo2LowValue: configuration?.spo2?.low?.value ?? 100,
        spo2HighValue: configuration?.spo2?.high?.value ?? 100,
        spo2Sensibility: configuration?.spo2?.sensibility?.seconds ?? 5,
        rrLowValue: configuration?.rr?.low?.value ?? 0,
        rrHighValue: configuration?.rr?.high?.value ?? 100,
        rrSensibility: configuration?.rr?.sensibility?.seconds ?? 5,
    };
}

function EventConfigurationForm({className, readonly, patientId, onChanged, onSaved, onCancel}: EventConfigurationFormProps) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [selectedEventConfiguration, setSelectedEventConfiguration] = useState<SelectedEventConfiguration>(convertEventConfiguration(null));

    const [/*currentEventConfigurationLoading*/, currentEventConfiguration, /*error*/, /*refreshCurrentEventConfiguration*/] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getEventConfiguration,
        memoize: true,
        data: { patientId: { id: patientId } },
    });

    useEffect(() => {
        checkDataChanged();
    }, [selectedEventConfiguration]);

    useEffect(() => {
        setSelectedEventConfiguration(convertEventConfiguration(currentEventConfiguration));
    }, [currentEventConfiguration]);

    const checkDataChanged = () => {
        const dataChanged = !deepEqual(convertEventConfiguration(currentEventConfiguration), selectedEventConfiguration);
        if (onChanged) {
            onChanged(dataChanged);
        }
    };

    const saveEventConfiguration = () => {
        setLoading(true);

        const formattedEventConfiguration: EventConfiguration = {
            configuration: {
                hr: {
                    high: {
                        value: selectedEventConfiguration.hrHighValue
                    },
                    low: {
                        value: selectedEventConfiguration.hrLowValue
                    },
                    sensibility: {
                        seconds: selectedEventConfiguration.hrSensibility
                    }
                },
                spo2: {
                    high: {
                        value: selectedEventConfiguration.spo2HighValue
                    },
                    low: {
                        value: selectedEventConfiguration.spo2LowValue
                    },
                    sensibility: {
                        seconds: selectedEventConfiguration.spo2Sensibility
                    }
                },
                rr: {
                    high: {
                        value: selectedEventConfiguration.hrHighValue
                    },
                    low: {
                        value: selectedEventConfiguration.hrLowValue
                    },
                    sensibility: {
                        seconds: selectedEventConfiguration.hrSensibility
                    }
                },
            },
        };

        BackendApiService.getRequest({
            domain: 'signal',
            modelName: 'updateEventConfiguration',
            data: {
                patientId: patientId,
                eventConfiguration: formattedEventConfiguration,
            },
        })
            .then(response => {
                FeedbackAlertsService.add(AlertTypeEnum.SUCCESS, 'updateEventConfiguration', t(['Event configuration updated!', 'eventConfiguration.messages.success']));
                return response;
            })
            .catch(err => {
                console.error(err);
            })
            .then(() => {
                // TODO doing changes like that will be a Nightmare to maintain → create a service
                BackendApiRefreshListeners.refreshByCall(SignalQueryServiceClient, SignalQueryServiceClient.prototype.getAverageEventsPerHoursForPeriod);
                BackendApiRefreshListeners.refreshByCall(SignalQueryServiceClient, SignalQueryServiceClient.prototype.getEventConfiguration);
                BackendApiRefreshListeners.refreshByCall(SignalQueryServiceClient, SignalQueryServiceClient.prototype.getEventsForPeriod);
                BackendApiRefreshListeners.refreshByCall(SignalQueryServiceClient, SignalQueryServiceClient.prototype.getEventsList);
                BackendApiRefreshListeners.refreshByCall(SignalQueryServiceClient, SignalQueryServiceClient.prototype.getEventsPerDay);
                BackendApiRefreshListeners.refreshByCall(SignalQueryServiceClient, SignalQueryServiceClient.prototype.getEventsPerHour);
                BackendApiRefreshListeners.refreshByCall(SignalQueryServiceClient, SignalQueryServiceClient.prototype.getSignalEventsSummary);
                BackendApiRefreshListeners.refreshByCall(SignalQueryServiceClient, SignalQueryServiceClient.prototype.getTypedEventsList);
                BackendApiRefreshListeners.refreshByCall(SignalQueryServiceClient, SignalQueryServiceClient.prototype.getSignal);
            })
            .then(() => {
                setLoading(false);
                if (onSaved) {
                    onSaved();
                }
            });
    };

    const handleCancelEventConfiguration = () => {
        setSelectedEventConfiguration(convertEventConfiguration(currentEventConfiguration));
        if (onCancel) {
            onCancel();
        }
    };

    const handleChangePulseRateThresholdConfiguration = (range: [number, number]) => {
        setSelectedEventConfiguration((prevState) => ({
            ...prevState,
            hrLowValue: range[0],
            hrHighValue: range[1],
        }));
    };

    const handleChangePulseRateSensibilityConfiguration = (value: number) => {
        setSelectedEventConfiguration((prevState) => ({
            ...prevState,
            hrSensibility: value,
        }));
    };

    const handleChangeSpo2ThresholdConfiguration = (value: number) => {
        setSelectedEventConfiguration((prevState) => ({
            ...prevState,
            spo2LowValue: value,
            spo2HighValue: 100,
        }));
    };

    return (
        <StyledWrapper className={`event-configuration-form ${className ?? ''}`}>
            {!currentEventConfiguration && (
                <LoadingView />
            )}
            {currentEventConfiguration && (
                <>
                    <div key="item1" className="signal-event-configuration">
                        <span>{t(['Pulse Rate (bpm)', 'thresholds.pulseRate'])}</span>
                        <EventThresholdConfigurationSlider
                            lowValue={selectedEventConfiguration.hrLowValue}
                            highValue={selectedEventConfiguration.hrHighValue}
                            sliderMin={50}
                            sliderMax={200}
                            sliderStep={5}
                            signalType={SignalType.Signal_PR}
                            onChanged={handleChangePulseRateThresholdConfiguration}
                        />

                        <span>{t(['Sensitivity (seconds)', 'eventConfiguration.sensibility'])}</span>
                        <EventSensibilityConfigurationSlider
                            value={selectedEventConfiguration.hrSensibility}
                            sliderMin={0}
                            sliderMax={30}
                            sliderStep={1}
                            signalType={SignalType.Signal_PR}
                            onChanged={handleChangePulseRateSensibilityConfiguration}
                        />
                    </div>
                    <div key="item2" className="signal-event-configuration">
                        <span>{t(['SpO2 (%)', 'thresholds.spO2'])}</span>
                        <EventThresholdConfigurationSlider
                            lowValue={selectedEventConfiguration.spo2LowValue}
                            highValue={selectedEventConfiguration.spo2HighValue}
                            sliderMin={70}
                            sliderMax={100}
                            sliderStep={1}
                            signalType={SignalType.Signal_SPO2}
                            onChanged={handleChangeSpo2ThresholdConfiguration}
                        />
                    </div>

                    <FlexLayout key="item3" direction="row" className="step-form-actions">
                        <FlexChild grow={0}>
                            <Button className="button-create" loading={loading} disabled={readonly} onClick={saveEventConfiguration}>{t(['Save', 'patient.save'])}</Button>
                            {onCancel && (
                                <Button className="button-cancel" onClick={handleCancelEventConfiguration}>{t(['Cancel', 'eventConfiguration.cancel'])}</Button>
                            )}
                        </FlexChild>

                        <FlexChild grow={0}>
                            <InfoTooltip className="eventConfigurationInfoButton" title={t(['Event configuration', 'infoButton.eventConfiguration.title'])} overlayPosition={'bottomRight'}>
                                <p>
                                    {t(['A biometric measurement point is considered as an event when the biometric is outside the range defined by the thresholds for this biometric, during a predefined duration defined by the sensitivity.', 'infoButton.eventConfiguration.text'])}
                                </p>
                                <p>
                                    {t(['At patient creation, default thresholds are suggested based on patient age.', 'infoButton.eventConfiguration.default'])}
                                </p>
                            </InfoTooltip>
                        </FlexChild>
                    </FlexLayout>
                </>
            )}

            {readonly &&
                <InfoMessage infoMessage={t(['Event configuration changes cannot be applied to Demo Patient.', 'eventConfiguration.demoInfoMessage'])}/>
            }
        </StyledWrapper>
    );
}

//language=SCSS
const StyledWrapper = styled.div`
& {
    min-width: 740px;
    
    .button-event-configuration {
        position: absolute;
        right: 10px;
        top: 5px;
        padding-left: 45px;
        background-image: url(${sliderIcon});
        background-repeat: no-repeat;
        background-position: 15px center;
        border-radius: 20px;
    }
    .signal-event-configuration {
        margin-top: 15px;
        margin-bottom: 30px;
        > span {
            display: inline-block;
            width: 130px;
            vertical-align: middle;
            text-align: left;
        }
        width: 800px;
    }

    .step-form-actions {
        align-items: center;
        gap: 12px;
    }

    .eventConfigurationInfoButton {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
`;

export { EventConfigurationForm };
