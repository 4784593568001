import {subDays, subYears} from 'date-fns';
import {PatientQueryServiceClient} from 'gabi-api-ts/v2/patient/query/patient_query.client';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {PatientHealthConditionDetailsUpdate} from '@/components/business/analytics/patient/patient-health-condition/patient-health-condition-details-update';
import PatientHealthConditionLabel from '@/components/business/analytics/patient/patient-health-condition/patient-health-condition-label';
import {Button} from '@/components/buttons/button';
import {TagTooltip} from '@/components/buttons/tag-tooltip';
import {Modal} from '@/components/layout/modal';
import {useBackendQuery} from '@/hooks/use-backend-query';
import {BackendApiRefreshListeners} from '@/services/backend-api-refresh-listeners';
import {colorPalette, zIndices} from '@/themes/darkmode';
import {NewDateUtil} from '@/util/new-date-util';

const PatientHealthConditionProps = {
    patient: PropTypes.object.isRequired,
};

const PatientHealthCondition = ({patient}) => {
    const { t } = useTranslation();
    const [showHealthConditionExtendedData, setShowHealthConditionExtendedData] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    const [/*healthConditionLoading*/, healthCondition] = useBackendQuery({
        serviceClient: PatientQueryServiceClient,
        query: PatientQueryServiceClient.prototype.getHealthCondition,
        memoize: true,
        data: patient.patientId,
    });
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [/*healthConditionTypesLoading*/, healthConditionTypes] = useBackendQuery({
        serviceClient: PatientQueryServiceClient,
        query: PatientQueryServiceClient.prototype.listHealthConditionTypes,
        memoize: true,
        data: {},
    });
    const [healthConditionTypesSelected, setHealthConditionTypesSelected] = useState(healthCondition?.healthConditionTypes.healthConditionTypes);
    
    useEffect(() => {
        if (healthCondition) {
            setHealthConditionTypesSelected(healthCondition.healthConditionTypes.healthConditionTypes);
        }
    }, [healthCondition]);
    
    const isDemoPatient = patient.patientId.id === 'demo';

    let isOldChild = false;
    let isNeoChild = false;

    if (patient.birthDate) {
        const today = NewDateUtil();
        const birthDate = new Date(patient.birthDate.year, patient.birthDate.month - 1, patient.birthDate.day);
        const oldChildDate = subYears(today, 13);
        const neoChildDate = subDays(today, 28);

        oldChildDate.setHours(birthDate.getHours(), birthDate.getMinutes(), birthDate.getSeconds(), birthDate.getMilliseconds());
        neoChildDate.setHours(birthDate.getHours(), birthDate.getMinutes(), birthDate.getSeconds(), birthDate.getMilliseconds());

        isOldChild = birthDate <= oldChildDate;
        isNeoChild = birthDate > neoChildDate;
    }

    const handleClickExtendHealthCondition = () => {
        setShowHealthConditionExtendedData(!showHealthConditionExtendedData);
    };

    const handleClickEditHealthCondition = () => {
        setIsModalOpen(true);
        setShowHealthConditionExtendedData(false);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleHealthConditionChanged = () => {
        setIsModalOpen(false);
        // TODO doing changes like that will be a Nightmare to maintain → create a service
        BackendApiRefreshListeners.refreshByCall(PatientQueryServiceClient, PatientQueryServiceClient.prototype.getHealthCondition);
    };

    return (
        <StyledWrapper>
            <div className="health-condition-info">
                <h3>{patient.firstName || patient.externalSource?.idInSource || patient.patientId.id}{t('healthCondition.title')}</h3>
                {healthConditionTypesSelected &&
                    <div className="healthConditionLabels">
                        <AgeTooltips isNeoChild={isNeoChild} isOldChild={isOldChild} />

                        {healthConditionTypesSelected.map(healthConditionLabel => (
                            <PatientHealthConditionLabel key={healthConditionLabel} healthConditionLabel={healthConditionLabel} />
                        ))}
                    </div>
                }
                {healthCondition ?
                    <div>
                        {healthCondition.healthConditionDetail}
                    </div>
                    :
                    <div><em>{t('healthCondition.empty')}</em></div>
                }
            </div>

            {!isDemoPatient &&
                <div className="health-condition-extend-data-action">
                    <Button className="button-short-extend" displayIcon={true} onClick={handleClickExtendHealthCondition}>{t('healthCondition.extend')}</Button>
                </div>
            }

            {showHealthConditionExtendedData &&
                <div className="health-condition-extended-data">
                    <div className="health-condition-minimize-data-action">
                        <Button className="button-short-minimize" displayIcon={true} onClick={handleClickExtendHealthCondition}>{t('healthCondition.minimize')}</Button>
                    </div>

                    <div className="health-condition-info">
                        <h3>{patient.firstName || patient.externalSource?.idInSource || patient.patientId.id}{t('healthCondition.title')}</h3>
                        {healthConditionTypesSelected &&
                            <div className="healthConditionLabels">
                                <AgeTooltips isNeoChild={isNeoChild} isOldChild={isOldChild} />
                                
                                {healthConditionTypesSelected.map(healthConditionLabel => (
                                    <PatientHealthConditionLabel key={healthConditionLabel} healthConditionLabel={healthConditionLabel} />
                                ))}
                            </div>
                        }
                        {healthCondition ?
                            <div>
                                {healthCondition.healthConditionDetail}
                            </div>
                            :
                            <div><em>{t('healthCondition.empty')}</em></div>
                        }
                    </div>

                    <div className="health-condition-update-actions">
                        <Button className="button-edit" onClick={handleClickEditHealthCondition}>
                            {t('healthCondition.update')}
                        </Button>
                    </div>
                </div>
            }

            {isModalOpen && !!healthConditionTypes &&
                <Modal title={t('healthCondition.update')} onClosed={handleCloseModal}>
                    <PatientHealthConditionDetailsUpdate
                        patient={patient}
                        healthConditionDetails={healthCondition?.healthConditionDetail}
                        healthConditionTypes={healthConditionTypes?.healthConditionTypes ?? []}
                        healthConditionTypesSelected={healthConditionTypesSelected ?? []}
                        onSaved={handleHealthConditionChanged}
                    />
                </Modal>
            }
        </StyledWrapper>
    );
};

PatientHealthCondition.propTypes = PatientHealthConditionProps;

const AgeTooltipsProps = {
    isOldChild: PropTypes.bool.isRequired,
    isNeoChild: PropTypes.bool.isRequired,
};
function AgeTooltips({isOldChild, isNeoChild}) {
    const { t } = useTranslation();
    
    return (<>
        {isOldChild &&
            <TagTooltip isDanger title={t('patient.oldChild.title')} overlayPosition="bottomRight">
                <p>
                    {t('patient.oldChild.firstText')}<br />
                    {t('patient.oldChild.secondText')}
                </p>
            </TagTooltip>
        }

        {isNeoChild &&
            <TagTooltip isDanger title={t('patient.neoChild.title')} overlayPosition="bottomRight">
                <p>
                    {t('patient.neoChild.firstText')}<br />
                    {t('patient.neoChild.secondText')}
                </p>
            </TagTooltip>
        }
    </>);
}
AgeTooltips.propTypes = AgeTooltipsProps;

//language=SCSS
const StyledWrapper = styled.div`
& {
    display:flex;
    width: 200px;
    margin: 0 auto;
    text-align: left;
    
    .health-condition-info {
        display:inline-block;
        vertical-align: top;
        width: 175px;
        h3 {
            font-size:16px;
            margin-top:0;
        }
    }
    
    .healthConditionLabels {
        margin:5px 0 10px 0;
        > div {
            margin-right:5px;
            margin-top:5px;
        }
    }
    
    .health-condition-extend-data-action {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
    
    .health-condition-extended-data {
        position: absolute;
        top: 3px;
        left: 0;
        padding: 20px;
        background: ${colorPalette.thirdBackground};
        width: 325px;
        z-index: ${zIndices.overlays};
        border-radius:0 5px 5px 0;

        .health-condition-info {
          display: inline-block;
          vertical-align: top;
          padding-top: 7px;
          width: 250px;
        }

        .health-condition-minimize-data-action {
           position: absolute;
           top: 8px;
           right: 8px;
        }
        
        .health-condition-update-actions {
            border-top:1px solid ${colorPalette.secondBackground};
            padding-top:20px;
            margin-top: 15px;
            button {
                margin-bottom:10px;
            }
        }
    }
}
`;

export {PatientHealthCondition};
