import deepEqual from 'deep-eql';
import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import Styled from 'styled-components';

import HcpCreationForm from '@/components/business/analytics/hcp/hcp-creation-form.jsx';
import ErrorBoundary from '@/components/errors/error-boundary.jsx';
import {BackendApiService} from '@/services/backend-api-service.js';

class HcpCreation extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        onCreated: PropTypes.func.isRequired,
    };

    state = {
        loading: false,
        errorMessage: null,
    };
    
    render() {
        const props = this.props;
        const state = this.state;

        return (
            <div className={props.className}>
                <ErrorBoundary>
                    <HcpCreationForm loading={state.loading} errorMessage={state.errorMessage || ''} onSubmit={(hcp) => this.createHcp(hcp)} />
                </ErrorBoundary>
            </div>
        );
    }

    async createHcp(hcp) {
        this.setState(state => ({
            ...state,
            loading: true,
        }));


        BackendApiService.getRequestWithoutToken({
            domain: 'hcp',
            modelName: 'createHcp',
            data: {
                hcp: hcp,
            }
        })
            .then((hcp) => {
                this.setState(state => ({
                    ...state,
                    loading: false,
                }));
                if(this.props.onCreated) {
                    this.props.onCreated(hcp);
                }
                return hcp;
            })
            .catch(err => {
                console.error(err);
                this.setState(state => ({
                    ...state,
                    loading: false,
                    errorMessage: err.message || this.props.t(['An error occurred. Cannot create account.', 'hcpCreation.api.createHcpError'])
                }));
            });
    }
 
    shouldComponentUpdate(nextProps, nextState) {
        return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
    }
}

//language=SCSS
HcpCreation = Styled(HcpCreation)`
& {
}
`;

export default withTranslation()(HcpCreation);
