import {useState} from 'react';

import {TableSortableColumn} from '@/components/table/table-sortable-column';
import ComponentErrorMessage from '@/components/widgets/component-error-message';

type TableSortableHeadProps = {
    columns: TableSortableColumn[];
    handleSorting: (accessor: string, sortOrder : 'asc' | 'desc' | 'default') => void;
}

const TableSortableHead = ({ columns, handleSorting }: TableSortableHeadProps) => {
    const firstSortableColumn = columns?.filter(c => c.sortable)[0] ?? { sortable: true, label: '' };
    const [sortField, setSortField] = useState(firstSortableColumn.accessor);
    const [order, setOrder] = useState('default');

    const handleSortingChange = (accessor: string) => {
        const sortOrder = (
            (accessor === sortField && order === 'asc') ? 'desc' :
                (accessor === sortField && order === 'desc') ? 'default' : 'asc'
        );
        setSortField(accessor);
        setOrder(sortOrder);
        handleSorting(accessor, sortOrder);
    };

    if (columns && columns.length > 0) {
        return (
            <thead>
                <tr>
                    {columns.map(({label, subLabel, className, accessor, sortable}: TableSortableColumn) => {
                        const sortableClassName = (() => {
                            if (!sortable || sortField !== accessor) {
                                return '';
                            }
                            else if (order === 'asc') {
                                return 'up';
                            }
                            else if (order === 'desc') {
                                return 'down';
                            }
                            else {
                                return 'default';
                            }
                        })();
                        return (
                            <th
                                key={accessor}
                                data-accessor={accessor}
                                onClick={sortable && accessor ? () => handleSortingChange(accessor) : undefined}
                                className={`${className ?? ''} ${sortableClassName}`}
                            >
                                {label}
                                {subLabel ? <span>{subLabel}</span> : ''}
                            </th>
                        );
                    })}
                </tr>
            </thead>
        );
    }
    else {
        return <ComponentErrorMessage component="TableSortableHead" />;
    }
};

export {TableSortableHead};
