import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';
import {connect as redux_connect} from 'react-redux';
import Styled from 'styled-components';

import {GroupUserRole} from '@/components/business/analytics/group/group-user-role.jsx';
import {BackendApiService} from '@/services/backend-api-service.js';

const reduxMapStateToProps = function(state) {
    return {
        me: state.me,
    };
};

class GroupUserChangeRole extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        userId: PropTypes.string.isRequired,
        groupId: PropTypes.string.isRequired,
        isAdmin: PropTypes.bool.isRequired,
        adminCount: PropTypes.number.isRequired,
        isMeGroupAdmin: PropTypes.bool.isRequired,
        onRoleChanged: PropTypes.func,
    };

    state = {
        userRole: this.props.isAdmin,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const props = this.props;
        const { t } = this.props;

        return (
            <div className={`${this.props.className} changeRoleWrapper`}>
                <input
                    type="checkbox"
                    name={`userRole-${props.groupId}-${props.userId}`}
                    id={`userRole-${props.groupId}-${props.userId}`}
                    checked={props.isAdmin}
                    disabled={!props.isMeGroupAdmin || (this.state.userRole && props.adminCount <= 1)}
                    onChange={this.handleChangeUserRole}
                />
                <label htmlFor={`userRole-${props.groupId}-${props.userId}`}>{t(['Admin', 'userChangeRole.role'])}</label>
            </div>
        );
    }

    handleChangeUserRole(e) {
        this.setState(state => ({
            ...state,
            userRole: e.target.checked,
        }));

        this.changeUserRole(e.target.checked);
    }

    async changeUserRole(isAdmin) {
        try {
            await BackendApiService.getRequest({
                domain: 'group',
                modelName: 'changeUserRole',
                data: {
                    groupId: this.props.groupId,
                    userId: this.props.userId,
                    role: (isAdmin) ? GroupUserRole.ADMIN : GroupUserRole.MEMBER
                }
            });

            if(this.props.onRoleChanged) {
                this.props.onRoleChanged();
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    shouldComponentUpdate() {
        return true;
    }
}

//language=SCSS
GroupUserChangeRole = Styled(GroupUserChangeRole)`
& {   
    display:flex;
    margin:15px 0;
    input {
        width:25px;
        height:25px;
    }
    label {
        margin: 2px 0 0 10px;
    }
}
`;


export default redux_connect(reduxMapStateToProps)(withTranslation()(GroupUserChangeRole));
