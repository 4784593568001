import {subDays} from 'date-fns';

import {NewDateUtil} from '@/util/new-date-util';

const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Tuesday', 'Friday', 'Saturday'];

export function formatDateUniversal(date: Date) {
    if (date) {
        const day = (date.getDate() < 10) ? `0${date.getDate()}` : date.getDate();
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();

        return day + '-' + month + '-' + year;
    }
}

export function formatTimeUniversal(date: Date) {
    if (date) {
        const hours = (date.getHours() < 10) ? `0${date.getHours()}` : date.getHours();
        const minutes = (date.getMinutes() < 10) ? `0${date.getMinutes()}` : date.getMinutes();
        const seconds = (date.getSeconds() < 10) ? `0${date.getSeconds()}` : date.getSeconds();

        return hours + ':' + minutes + ':' + seconds;
    }
}

export function formatDateTimeUniversal(date: Date) {
    if (date) {
        return `${formatDateUniversal(date)} ${formatTimeUniversal(date)}`;
    }
}

export function formatDateShortUniversal(date: Date) {
    if (date) {
        const day = (date.getDate() < 10) ? `0${date.getDate()}` : date.getDate();
        const month = monthNames[date.getMonth()];

        return day + '-' + month;
    }
}

export function formatDateTimeWeekday(date: Date) {
    if (date) {
        const today = NewDateUtil();
        const yersterday = subDays(today, 1);
        const isToday = today.getDate() === date.getDate() && today.getMonth() === date.getMonth() && today.getFullYear() === date.getFullYear();
        const isYersterday = yersterday.getDate() === date.getDate() && yersterday.getMonth() === date.getMonth() && yersterday.getFullYear() === date.getFullYear();

        const weekday = weekdays[date.getDay()];
        const hours = (date.getHours() < 10) ? `0${date.getHours()}` : date.getHours();
        const minutes = (date.getMinutes() < 10) ? `0${date.getMinutes()}` : date.getMinutes();
        const seconds = (date.getSeconds() < 10) ? `0${date.getSeconds()}` : date.getSeconds();

        if (isToday) {
            return 'Today' + ', ' + hours + ':' + minutes + ':' + seconds;
        }
        else if (isYersterday) {
            return 'Yesterday' + ', ' + hours + ':' + minutes + ':' + seconds;
        }
        else {
            return weekday + ', ' + hours + ':' + minutes + ':' + seconds;
        }
    }
}

export function formatSecondsToHms(date: Date | number) {
    const timestamp = Number(date);
    const h = Math.floor(timestamp / 3600);
    const m = Math.floor(timestamp % 3600 / 60);
    const s = Math.floor(timestamp % 3600 % 60);

    const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
    const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
    const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
    return hDisplay + mDisplay + sDisplay;
}

/**
 * Checks that a date with format "YYYY/mm/dd" or "YYYY-mm-dd" is a valid day
 * @param dateString
 */
export function isDateStringValid(dateString: string) {
    // Check if the string matches the format
    // Regular expression to match the "YYYY/mm/dd" or "YYYY-mm-dd" format
    if (!dateString.match(/^\d{4}[-/]\d{2}[-/]\d{2}$/)) {
        return false;
    }

    const parts = dateString.split(/[-/]/);
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const day = parseInt(parts[2], 10);

    // Check the range of year, month and day are in range
    if (year < 1000 || year > 9999 || month < 1 || month > 12 || day < 1 || day > 31) {
        return false;
    }

    // Create a date object and check if the date is valid
    const date = new Date(year, month - 1, day);
    return (
        date.getFullYear() === year &&
        date.getMonth() === month - 1 &&
        date.getDate() === day
    );
}
