import {Patient} from 'gabi-api-ts/v2/patient/query/patient_query';
import {AlertType, SignalType} from 'gabi-api-ts/v2/signal/query/signal_query';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import {HcpTutorialStatus} from '@/components/business/analytics/hcp/hcp-tutorial-status';
import {AverageCell} from '@/components/business/analytics/patient-overview-cells/average-cell';
import {EventsCell} from '@/components/business/analytics/patient-overview-cells/events-cell';
import PatientAvatar from '@/components/business/analytics/patient/common/patient-avatar';
import PatientLastRecording from '@/components/business/analytics/patient/common/patient-last-recording';
import {HcpProgramConfig} from '@/components/business/analytics/patients/hcp-program-config';
import {Button} from '@/components/buttons/button';
import {PatientChildSexLabel} from '@/enum/patient-child-sex-enum';
import {formatApiDateToJSDate} from '@/services/api-requests/requests-utils';
import {BackendApiService} from '@/services/backend-api-service';
import {TutorialService} from '@/services/tutorial-service';
import {tutorialStatus as tutorialStatusReduxModel} from '@/store/models/index';
import {colorPalette} from '@/themes/darkmode';
import {formatDateUniversal} from '@/util/date-utils';
import {formatDateForUrl} from '@/util/url-util';

import '@/stylesheets/bootstrap.min.css';

type PatientsActiveTableRowProps = {
    className?: string;
    data: Patient;
    programConfig: HcpProgramConfig;
};

function PatientsActiveTableRow({className, data, programConfig}: PatientsActiveTableRowProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Tutorial Status from Redux
    const tutorialStatus: Record<string, unknown> = useSelector<{tutorialStatus: object}>(state => state.tutorialStatus) as Record<string, unknown>;
    const dispatch = useDispatch();

    const patient = data;

    const handleClickHideDemoPatient = (e: Event) => {
        e.preventDefault();

        BackendApiService.getRequest({
            domain: 'hcp',
            modelName: 'updateTutorialStatus',
            status: {
                demoPatient: HcpTutorialStatus.DISABLED,
            },
        }).then(async (response) => {
            const updatedTutorialStatus = {...tutorialStatus};
            updatedTutorialStatus.demoPatient = HcpTutorialStatus.DISABLED;

            dispatch(tutorialStatusReduxModel.actions.set(updatedTutorialStatus));
            await TutorialService.init();

            return response;
        }).catch(err => {
            console.error(err);
        });
    };

    const handleWidgetChartClick = (selectedDate: Date) => {
        const url = `/patients/${patient.patientId!.id}/timeline/${formatDateForUrl(selectedDate)}`;
        navigate(url);
    };

    return (
        <StyledWrapper className={`patients-active-table-row ${className ?? ''}`}>
            <td className="linkCell">
                <Link to={`/patients/${patient.patientId!.id}/healthReport`}>{t(['Patient details', 'pages.patients.patientDetails'])}</Link>
            </td>
            <td className="patientNameCell">
                <div>
                    <PatientAvatar skinType={patient.skinType} />
                    <p>
                        {patient.firstName || patient.externalSource?.idInSource || patient.patientId!.id} {patient.lastName}
                        <span>
                            {patient.birthDate ?
                                <>{formatDateUniversal(formatApiDateToJSDate(patient.birthDate))}&nbsp;-&nbsp;</>
                                :
                                <><em>{t(['No birth date information', 'patient.birthDateEmpty'])}&nbsp;-&nbsp;</em></>
                            }
                            {t('patient.sexLabel.' + PatientChildSexLabel[patient.sex])}
                        </span>
                        <span>{patient.trialId?.id}</span>
                    </p>
                    {patient.patientId!.id === 'demo' &&
                        <Button
                            className="button-hide-filled-hover"
                            displayIcon={true}
                            onClick={handleClickHideDemoPatient}
                        >
                            {t(['Hide', 'pages.patients.hide'])}
                        </Button>
                    }
                </div>
            </td>
            <td className="lastDataCell">
                <PatientLastRecording lastRecordingDataTime={patient.lastRecordingDataTime} />
            </td>

            {programConfig.pulseRate.average && (
                <AverageCell patient={patient} signalType={SignalType.Signal_PR} handleWidgetChartClick={handleWidgetChartClick} />
            )}

            {programConfig.spo2.average && (
                <AverageCell patient={patient} signalType={SignalType.Signal_SPO2}
                    handleWidgetChartClick={handleWidgetChartClick}
                />
            )}

            {programConfig.pulseRate.eventsBelow && (
                <EventsCell alertType={AlertType.Alert_LOW} patient={patient} signalType={SignalType.Signal_PR}
                    handleWidgetChartClick={handleWidgetChartClick} unit={t('global.eventsPerHour')}
                />
            )}

            {programConfig.pulseRate.eventsAbove && (
                <EventsCell alertType={AlertType.Alert_HIGH} patient={patient} signalType={SignalType.Signal_PR}
                    handleWidgetChartClick={handleWidgetChartClick} unit={t('global.eventsPerHour')}
                />
            )}

            {programConfig.spo2.eventsBelow && (
                <EventsCell alertType={AlertType.Alert_LOW} patient={patient} signalType={SignalType.Signal_SPO2}
                    handleWidgetChartClick={handleWidgetChartClick} unit={'%'}
                />
            )}
        </StyledWrapper>
    );
}

//language=SCSS
const StyledWrapper = styled.tr`
& {
    position: relative;

    td {
        min-height: 70px;
        width: 160px;
        
        &.linkCell {
          width: 80px;
        }

        &.lastDataCell {
            width: 180px;
        }
        
        &.patientNameCell {
            width: auto;
            p {
                flex-grow: 3;
            }
            .button-hide-filled-hover {
                flex-grow: 1;
                margin-left:15px;
                text-align:left;
                background-color ${colorPalette.clearColor};
            }
        }
    }
    
    > td:nth-child(1 of .hoverableCell) {
        border-left-width: 5px;
    }
    
    .noDataTooltip {
        top: 8px;
        right: 8px;
    }
    
    .warning-overlay {
        background: ${colorPalette.thirdBackground};
        margin-left: 20px;
        border-radius: 5px;
        z-index: 1050;
        position: absolute;
        width: 500px;
        right: 0;
        top:55px;
        box-shadow: 0 1px 3px ${colorPalette.darkColor};
    
        .trendsNoData {
            font-style: italic;
            margin-top:20px;
        }
        
        .average-block {
            text-align:right;
            position: relative;
            font-size: 13px;
            
            p {
                margin-bottom: 5px;
                span {
                    display:inline;
                }
            }
        }
    }
}
`;

export {PatientsActiveTableRow};
