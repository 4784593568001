import deepEqual from 'deep-eql';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';
import {connect as redux_connect} from 'react-redux';
import {Link} from 'react-router-dom';
import Styled from 'styled-components';

import {HcpTutorialStatus} from '@/components/business/analytics/hcp/hcp-tutorial-status';
import {Modal} from '@/components/layout/modal';
import LegalMentions from '@/components/static/legal-mentions';
import helpIcon from '@/public/icons/help-icon.svg';
import profileIcon from '@/public/icons/user-icon.svg';
import {BackendApiService} from '@/services/backend-api-service';
import {TutorialService} from '@/services/tutorial-service';
import {tutorialStatus} from '@/store/models';
import {colorPalette} from '@/themes/darkmode';

const reduxMapStateToProps = function(state) {
    return {
        me: state.me,
        pagePath: state.pagePath,
        tutorialStatus: state.tutorialStatus,
    };
};

const reduxDispatch = function(dispatch) {
    return {
        setTutorialStatus: (status) => {
            dispatch(tutorialStatus.actions.set(status));
        },
    };
};

class HelpCenter extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        currentPage: PropTypes.any.isRequired,
        tutorialStatus: PropTypes.any.isRequired,
        setTutorialStatus: PropTypes.func,
    };

    state = {
        displayModalLegalMentions: false,
        helpCenterOpen: false,
    };
    
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        autoBind(this);
    }
    
    componentDidMount() {
        document.addEventListener('click', (e) => {
            if (this.state.helpCenterOpen && !this.ref.current.contains(e.target)){
                this.hideHelpCenter();
            }
        });
    }
    
    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={`help-center ${props.className} ${state.helpCenterOpen ? 'active' : ''}`} ref={this.ref}>
                {state.displayModalLegalMentions &&
                    <Modal title={t(['Legal Mentions', 'legalMentions.title'])} onClosed={this.handleCloseModal}>
                        <LegalMentions />
                    </Modal>
                }

                <div className="help-wrapper" onClick={this.toggleHelpCenter} />
    
                {this.renderHelpCenter()}
            </div>
        );
    }

    renderHelpCenter() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <ul className={`help-content ${state.helpCenterOpen ? 'active' : ''}`}>
                <li className="help-item">
                    <a href={`https://help.gabismartcare.com/hcp-${__APP_VERSION__}`} target="_blank" rel="noreferrer">{t(['Instructions for use', 'helpCenter.ifu'])}</a>
                </li>
                <li className="help-item">
                    <a href="https://help.gabismartcare.com/#hcp-documents" target="_blank" rel="noreferrer">{t(['Policies', 'helpCenter.policies'])}</a>
                </li>
                {props.tutorialStatus.demoPatient === HcpTutorialStatus.DISABLED && props.currentPage === 'Active patients' &&
                    <li className="help-item">
                        <a href="#" onClick={this.enableDemoPatient}>{t(['Demo patient', 'helpCenter.demo'])}</a>
                    </li>
                }
                {props.tutorialStatus.guidedTourPatient === HcpTutorialStatus.DISABLED && props.currentPage === 'Active patients' &&
                    <li className="help-item">
                        <a href="#" onClick={this.enableGuidedTour}>{t(['Guided tour', 'helpCenter.guidedTour'])}</a>
                    </li>
                }
                {props.tutorialStatus.guidedTourHealthReport === HcpTutorialStatus.DISABLED && props.currentPage === 'Health Report - last night' &&
                    <li className="help-item">
                        <a href="#" onClick={this.enableGuidedTour}>{t(['Guided tour', 'helpCenter.guidedTour'])}</a>
                    </li>
                }
                {props.tutorialStatus.guidedTourSignals === HcpTutorialStatus.DISABLED && props.currentPage === 'Daily biometrics' &&
                    <li className="help-item">
                        <a href="#" onClick={this.enableGuidedTour}>{t(['Guided tour', 'helpCenter.guidedTour'])}</a>
                    </li>
                }

                <li className="help-item">
                    <Link to="/changelog">{t(['Access change logs', 'helpCenter.changelog'])}</Link>
                </li>
                <li className="help-item">
                    <a href="mailto:support@gabismartcare.com">{t(['Contact information', 'helpCenter.contact'])}</a>
                </li>
                <li className="help-item">
                    <a href="" onClick={this.handleClickLegalMentions}>{t(['Legal Mentions', 'legalMentions.title'])}</a>
                </li>
            </ul>
        );
    }

    handleCloseModal() {
        this.setState(state => ({
            ...state,
            displayModalLegalMentions: false,
        }));
    }

    handleClickLegalMentions(e) {
        e.preventDefault();

        this.setState(state => ({
            ...state,
            displayModalLegalMentions: true,
        }));
    }
    
    hideHelpCenter() {
        this.setState(state => ({
            ...state,
            helpCenterOpen: false,
        }));
    }
    
    toggleHelpCenter() {
        this.setState(state => ({
            ...state,
            helpCenterOpen: !state.helpCenterOpen,
        }));
    }

    enableGuidedTour() {
        BackendApiService.getRequest({
            domain: 'hcp',
            modelName: 'updateTutorialStatus',
            status: {
                guidedTourPatient: HcpTutorialStatus.ENABLED,
            },
        }).then((tutorialStatus) => {
            const updatedTutorialStatus = Object.assign({}, this.props.tutorialStatus);
            updatedTutorialStatus.guidedTourPatient = HcpTutorialStatus.ENABLED;
            updatedTutorialStatus.guidedTourHealthReport = HcpTutorialStatus.ENABLED;
            updatedTutorialStatus.guidedTourSignals = HcpTutorialStatus.ENABLED;
            this.props.setTutorialStatus(updatedTutorialStatus);
            this.hideHelpCenter();

            return tutorialStatus;
        }).catch(err => {
            console.error(err);
        });
    }

    enableDemoPatient() {
        BackendApiService.getRequest({
            domain: 'hcp',
            modelName: 'updateTutorialStatus',
            status: {
                demoPatient: HcpTutorialStatus.ENABLED,
            },
        }).then(async (tutorialStatus) => {
            const updatedTutorialStatus = Object.assign({}, this.props.tutorialStatus);
            updatedTutorialStatus.demoPatient = HcpTutorialStatus.ENABLED;
            this.props.setTutorialStatus(updatedTutorialStatus);
            this.hideHelpCenter();
            
            await TutorialService.init();

            return tutorialStatus;
        }).catch(err => {
            console.error(err);
        });
    }
    
    shouldComponentUpdate(nextProps, nextState) {
        return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
    }
}

//language=SCSS
HelpCenter = Styled(HelpCenter)`
& {
    display: inline-block;
    position: relative;
    width: 100%;
    min-width: 70px;
    height: 100%;
    border-left: 3px solid ${colorPalette.mainBackground};
    background:url(${helpIcon}) no-repeat center;
    background-size:25px;
    
    &.active {
        background-color: ${colorPalette.activeColorDarker};
    }
    
    .help-wrapper {
        cursor: pointer;
        z-index: 2;
        position: relative;
        padding: 0 10px;
        height: 100%;
    }
    
    ul.help-content {
        position: fixed;
        background: ${props => props.theme.colorPalette.mainBackground};
        margin: 0;
        list-style: none;
        box-shadow: 0 2px 4px rgba(0,0,0,0.2);
        overflow: hidden;
        transition: opacity 0.15s ease-out, max-height 0.5s ease-out;
        opacity: 0;
        z-index: 1;
        max-height: 0;
        padding:0;
        
        &.active {
            max-height: 1000px;
            opacity: 1;
            display: block;
            transition: opacity 0.15s ease-out;
        }
    }
    
    .help-item {
        margin: 0;
        cursor: pointer;
        padding: 0;
        color: ${props => props.theme.colors.menu.text};
        white-space: nowrap;
        line-height: 40px;
        &:hover {
            background: ${props => props.theme.colors.menu.backgroundHover};
            color: ${props => props.theme.colors.menu.textHover};
        }
        > a {
            display: block;
            padding: 0 20px;
            cursor: pointer;
            color: ${props => props.theme.colors.menu.text};
            border-bottom:1px solid ${colorPalette.thirdBackground};
            border-top:1px solid ${colorPalette.darkColor};
            
            &:hover {
                color: ${props => props.theme.colors.menu.textHover};
                text-decoration: none;
            }

            &.menu-item-profile {
                background: url(${profileIcon}) no-repeat 25px center;
                background-size:20px;
            }
        }
        &:first-child a {
            border-top:none;
        }
        &:last-child a {
            border-bottom:none;
        }
    }
}`;

HelpCenter = redux_connect(reduxMapStateToProps, reduxDispatch)(withTranslation()(HelpCenter));
export {HelpCenter};
