import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {EventConfigurationForm} from '@/components/business/analytics/common/event-configuration-form';
import {Button} from '@/components/buttons/button';
import {Modal} from '@/components/layout/modal';
import sliderIcon from '@/public/icons/slider-icon.svg';
import {colorPalette} from '@/themes/darkmode';

type EventConfigurationButtonProps = {
    patientId: string;
};

const EventConfigurationButton = ({patientId}: EventConfigurationButtonProps) => {
    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);

    const handleClickOpenModalButton = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleSaved = () => {
        setModalOpen(false);
    };

    return (
        <StyledWrapper>
            <Button
                className="button-event-configuration"
                onClick={handleClickOpenModalButton}
            >
                {t(['Event configuration', 'eventConfiguration.title'])}
            </Button>
            {modalOpen && (
                <Modal title={t(['Event configuration', 'eventConfiguration.title'])} onClosed={closeModal} hideCloseButton>
                    <EventConfigurationForm
                        readonly={patientId === 'demo'}
                        patientId={patientId}
                        onSaved={() => { handleSaved(); }}
                        onCancel={() => { setModalOpen(false); }}
                    />
                </Modal>
            )}
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
    && {
        .button-event-configuration {
            position:absolute;
            right:10px;
            top:5px;
            padding-left:45px;
            background-image: url(${sliderIcon});
            background-repeat: no-repeat;
            background-position: 15px center;
            border-radius: 20px;
            &:hover, &:focus {
                background-color: ${colorPalette.activeColor};
                cursor: pointer;
            }
        }
        .signal-event-configuration {
            margin-top:15px;
            margin-bottom: 30px;
            > span {
                display: inline-block;
                width: 130px;
                vertical-align: middle;
                text-align: left;
            }
            width:800px;
        }
    }
`;

export {EventConfigurationButton};
