import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import PatientsSearchForm from '@/components/business/analytics/patients/patients-search-form.jsx';
import PatientsSearchTable from '@/components/business/analytics/patients/patients-search-table.jsx';
import LoadingView from '@/components/static/loading-view.tsx';
import searchBabyHello from '@/public/images/search-baby-hello.svg';
import {BackendApiService} from '@/services/backend-api-service.js';
import {colorPalette} from '@/themes/darkmode.js';

const PatientsSearch = () => {
    const { t } = useTranslation();
    const [patientsListData, setPatientsListData] = useState(null);
    const [patientsSearchData, setPatientsSearchData] = useState(null);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getPatients();
    }, []);

    useEffect(() => {
        if(search !== '') {
            const searchValues = search.toLowerCase().split(' ');
            let tempPatientsSearchData = [...patientsListData];

            searchValues.forEach(searchItem => {
                tempPatientsSearchData = tempPatientsSearchData.filter(patient => {
                    return patient.firstName?.toLowerCase().includes(searchItem) || patient.lastName?.toLowerCase().includes(searchItem);
                });
            });

            setPatientsSearchData(tempPatientsSearchData);
        } else {
            setPatientsSearchData(patientsListData);
        }
    }, [search]);

    const getPatients = () => {
        setLoading(true);

        BackendApiService.getRequest({
            modelName: 'getPatients',
            domain: 'patient',
        })
            .then((response) => {
                const patientsList = response.patientList;

                patientsList.forEach(patient => {
                    if(patient.lastRecordingDataTime) {
                        const lastRecordingDateTime = new Date(
                            patient.lastRecordingDataTime.date.year,
                            patient.lastRecordingDataTime.date.month - 1,
                            patient.lastRecordingDataTime.date.day,
                            patient.lastRecordingDataTime.time.hour,
                            patient.lastRecordingDataTime.time.minutes,
                            patient.lastRecordingDataTime.time.seconds
                        );
                        patient.lastRecordingDataTimeSortable = lastRecordingDateTime.getTime();
                    } else {
                        patient.lastRecordingDataTimeSortable = 10E15; // Create fake timestamp with huge value
                    }
                });
                
                setPatientsListData(patientsList);
                setPatientsSearchData(patientsList);
                setLoading(false);
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    };

    const handleChangeSearchForm = (search) => {
        setSearch(search);
    };

    return (
        <StyledWrapper>
            <div className="searchBlock">
                <div>
                    <img src={searchBabyHello} width={100} alt="" />
                </div>
                <div className="searchText">
                    <h2>{t(['Search patient', 'pages.patients.searchPatient'])}</h2>
                    <h3>{t(['You can search for a patient first name or last name.', 'pages.patients.searchPatientText'])}</h3>
                </div>
                <div className="searchActions">
                    {loading ?
                        <LoadingView size="small"/>
                        :
                        <PatientsSearchForm onChanged={handleChangeSearchForm}/>
                    }
                </div>
            </div>

            {!loading && patientsSearchData &&
                <PatientsSearchTable patients={patientsSearchData} />
            }
            {loading && <LoadingView size="large" /> }
        </StyledWrapper>
    );
};

PatientsSearch.propTypes = {
    t: PropTypes.func,
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      padding-bottom: 50px;
      
      .searchBlock {
        width: 100%;
        background: ${colorPalette.thirdBackground};
        border-radius: 10px;
        padding: 30px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-bottom: 30px;
        
        .searchText {
          margin-left: 20px;
        
          h2 {
            font-size: 16px;
          }
        
          h3 {
            font-size: 14px;
          }
        }
        
        .searchActions {
          margin-left: auto;
          display: flex;
        }
      }
  }
`;

export default PatientsSearch;
