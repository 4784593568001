import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';

import PatientChildForm from '@/components/business/common/patient/patient-child-form.jsx';
import {AlertTypeEnum} from '@/enum/alert-type-enum.jsx';
import {BackendApiService} from '@/services/backend-api-service.js';
import {FeedbackAlertsService} from '@/services/feedback-alerts-service.js';

class PatientUpdateChild extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        patient: PropTypes.object.isRequired,
        onChanged: PropTypes.func,
    };

    state = {
        loading: false,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <h3>{t(['Child information', 'updateChild.title'])}</h3>

                <PatientChildForm patient={props.patient} loading={state.loading} onChanged={this.handleFormChanged} onSubmit={(patient) => this.updatePatientChild(patient)} />
            </div>
        );
    }

    handleFormChanged(notSavedDataChild) {
        if(this.props.onChanged) {
            this.props.onChanged(notSavedDataChild);
        }
    }

    async updatePatientChild(patient) {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'patient',
            modelName: 'updatePatient',
            data: {
                patient: patient
            }
        }).then((patient) => {
            FeedbackAlertsService.add(AlertTypeEnum.SUCCESS, 'updatePatient', this.props.t(['Patient updated!', 'updateChild.updated']));
            this.setState(state => ({
                ...state,
                loading: false,
            }));
            return patient;
        }).catch(err => {
            console.error(err);
            this.setState(state => ({
                ...state,
                loading: false,
            }));
        });
    }
}

export default withTranslation()(PatientUpdateChild);

