import {HcpQueryServiceClient} from 'gabi-api-ts/v2/hcp/query/hcp_query.client';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {connect as redux_connect} from 'react-redux/es/exports';
import Styled from 'styled-components';

import GuidedTourPatient from '@/components/business/analytics/guidedTour/guided-tour-patient';
import {HcpTutorialStatus} from '@/components/business/analytics/hcp/hcp-tutorial-status';
import {PatientsActive} from '@/components/business/analytics/patients/patients-active';
import PatientsDashboardNavigation from '@/components/business/analytics/patients/patients-dashboard-navigation';
import PatientsWelcomeSection from '@/components/business/analytics/patients/patients-welcome-section';
import ErrorBoundary from '@/components/errors/error-boundary';
import LoadingView from '@/components/static/loading-view';
import {Page} from '@/decorators/page';
import {PatientDashboardTypeEnum} from '@/enum/patient-dashboard-type-enum';
import {useBackendQuery} from '@/hooks/use-backend-query';

function wrapWithPage(Component) {
    return Page({
        name: 'Active patients'
    })(Component);
}

const reduxMapStateToProps = function(state) {
    return {
        tutorialStatus: state.tutorialStatus,
    };
};

const PatientsActivePageUnstyledProps = {
    t: PropTypes.func,
    className: PropTypes.string,
    tutorialStatus: PropTypes.any,
};

function PatientsActivePageUnstyled(props) {
    const [programTypeOverride, setProgramTypeOverride] = useState(null);
    // const [loading, setLoading] = useState(true);
    //const [mounted, setMounted] = useMounted(true);
    
    const [programTypeLoading, programType] = useBackendQuery({
        serviceClient: HcpQueryServiceClient,
        query: HcpQueryServiceClient.prototype.getProgramType,
        data: {},
        memoize: true,
    });
    
    const programTypeToShow = programTypeOverride !== null ? programTypeOverride : programType?.programType;
    
    return (
        <div className={props.className}>
            <ErrorBoundary>
                {programTypeLoading ?
                    <LoadingView/>
                    :
                    <>
                        {props.tutorialStatus.guidedTourPatient === HcpTutorialStatus.ENABLED && (
                            <GuidedTourPatient />
                        )}
                        <PatientsDashboardNavigation activeDashboard={PatientDashboardTypeEnum.RECENT_RECORDING}/>
                        
                        {programTypeToShow !== null && (
                            <div>
                                <PatientsWelcomeSection program={programTypeToShow} onChanged={setProgramTypeOverride}/>
                                <PatientsActive program={programTypeToShow}/>
                            </div>
                        )}
                    </>
                }
            </ErrorBoundary>
        </div>
    );
}
PatientsActivePageUnstyled.prototype.propTypes = PatientsActivePageUnstyledProps;

//language=SCSS
const PatientsActivePage = Styled(PatientsActivePageUnstyled)`
& {
    min-height: 500px;
    width: 95%;
    height: calc(100% - 80px);
    margin: 30px auto 150px auto;
}
`;

export default redux_connect(reduxMapStateToProps)(wrapWithPage(PatientsActivePage));
