import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Styled from 'styled-components';

import PatientsDashboardNavigation from '@/components/business/analytics/patients/patients-dashboard-navigation.jsx';
import PatientsSearch from '@/components/business/analytics/patients/patients-search.jsx';
import ErrorBoundary from '@/components/errors/error-boundary.jsx';
import {Page} from '@/decorators/page.jsx';
import {PatientDashboardTypeEnum} from '@/enum/patient-dashboard-type-enum.jsx';

function wrapWithPage(Component) {
    return Page({
        name: 'Search patients'
    })(Component);
}

class PatientsSearchPage extends React.Component {
    static propTypes = {
        t: PropTypes.func,
        className: PropTypes.string,
    };

    render() {
        const props = this.props;

        return (
            <div className={props.className}>
                <ErrorBoundary>
                    <PatientsDashboardNavigation activeDashboard={PatientDashboardTypeEnum.SEARCH} />

                    <PatientsSearch />
                </ErrorBoundary>
            </div>
        );
    }
}

//language=SCSS
PatientsSearchPage = Styled(PatientsSearchPage)`
& {
    min-height: 500px;
    width: 95%;
    height: calc(100% - 80px);
    margin: 30px auto 0 auto;
}
`;

export default wrapWithPage(withTranslation()(PatientsSearchPage));

