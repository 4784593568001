import deepEqual from 'deep-eql';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {connect as redux_connect} from 'react-redux';
import Styled from 'styled-components';

import {Changelog} from '@/components/widgets/changelog';
import changelogGift from '@/public/icons/changelog-gift.svg';
import {BackendApiService} from '@/services/backend-api-service';
import {changelogGiftOpen} from '@/store/models';
import {colorPalette} from '@/themes/darkmode';

const reduxMapStateToProps = function(state) {
    return {
        me: state.me,
        pagePath: state.pagePath,
        changelogGiftOpen: state.changelogGiftOpen,
    };
};

const reduxDispatch = function(dispatch) {
    return {
        toggleChangelogGift: () => {
            dispatch(changelogGiftOpen.actions.toggle());
        },
        hideChangelogGift: () => {
            dispatch(changelogGiftOpen.actions.set(false));
        },
    };
};

class ChangelogGift extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        toggleChangelogGift: PropTypes.func,
        hideChangelogGift: PropTypes.func,
        changelogGiftOpen: PropTypes.bool,
        giftNotification: PropTypes.object.isRequired,
        onClosed: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        autoBind(this);
    }
    
    componentDidMount() {
        document.addEventListener('click', (e) => {
            if (this.props.changelogGiftOpen && !this.ref.current.contains(e.target)){
                this.props.hideChangelogGift();
                this.notifyGiftNotificationSeen();
            }
        });
    }
    
    render() {
        const props = this.props;

        return (
            <div className={`changelog-gift ${props.className} ${props.changelogGiftOpen ? 'active' : ''}`} ref={this.ref}>
                <div className="changelog-wrapper" onClick={this.handleChangelogClick} />
    
                {this.renderChangelogGift()}
            </div>
        );
    }

    renderChangelogGift() {
        const props = this.props;
        const from = props.giftNotification.time ? new Date(props.giftNotification.time.seconds * 1000) : null;
        
        return (
            <div className={`changelog-content ${props.changelogGiftOpen ? 'active' : ''}`}>
                <Changelog from={from} />
            </div>
        );
    }

    handleChangelogClick() {
        this.props.toggleChangelogGift();
        if(this.props.changelogGiftOpen) {
            this.notifyGiftNotificationSeen();
        }
    }

    notifyGiftNotificationSeen() {
        BackendApiService.getRequest({
            domain: 'hcp',
            modelName: 'notifyGiftNotificationSeen',
        }).then((giftNotification) => {
            if(this.props.onClosed) {
                this.props.onClosed();
            }
            return giftNotification;
        }).catch(err => {
            console.error(err);
        });
    }
    
    shouldComponentUpdate(nextProps) {
        return !deepEqual(this.props, nextProps);
    }
}

//language=SCSS
ChangelogGift = Styled(ChangelogGift)`
& {
    display: inline-block;
    position: relative;
    width: 100%;
    min-width: 70px;
    height: 100%;
    border-left: 3px solid ${colorPalette.mainBackground};
    background:url(${changelogGift}) no-repeat center;
    background-size:25px;
    &.active {
        background-color: ${colorPalette.activeColorDarker};
    }
    
    .changelog-wrapper {
        cursor: pointer;
        z-index: 2;
        position: relative;
        padding: 0 10px;
        height: 100%;
    }

    .changelog-content {
        position: fixed;
        background: ${props => props.theme.colorPalette.mainBackground};
        overflow-y: scroll;
        margin: 0;
        list-style: none;
        box-shadow: 0 2px 4px rgba(0,0,0,0.2);
        transition: opacity 0.15s ease-out, max-height 0.5s ease-out;
        opacity: 0;
        z-index: 1;
        max-height: 0;
        padding:0;

        &.active {
            max-height: 80vh;
            opacity: 1;
            display: block;
            transition: opacity 0.15s ease-out;
        }
    }
}`;

ChangelogGift = redux_connect(reduxMapStateToProps, reduxDispatch)(ChangelogGift);
export {ChangelogGift};
