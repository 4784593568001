import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import PatientsTableRow from '@/components/business/analytics/patients/patients-table-row';
import {InfoMessage} from '@/components/form/info-message';
import {TableSortable} from '@/components/table/table-sortable';
import ComponentErrorMessage from '@/components/widgets/component-error-message';

const PatientsInactiveTable = ({patients}) => {
    const { t } = useTranslation();

    const tableSortableHeaderColumn = [
        {
            label: '',
            accessor: 'patientId.id',
            sortable: false
        },
        {
            label: t(['Patient', 'patient.title']),
            accessor: 'lastName',
            sortable: true
        },
        {
            label: t(['Last recording', 'patient.lastRecording']),
            accessor: 'lastRecordingDataTimeSortable',
            sortable: true
        },
        {
            label: t(['Caregiver', 'caregiver.title']),
            accessor: 'caregiver',
            sortable: false
        },
        {
            label: '',
            accessor: 'edit',
            sortable: false
        },
        {
            label: '',
            accessor: 'delete',
            sortable: false
        },
    ];

    if(patients) {
        if(patients.length > 0) {
            return (
                <TableSortable
                    data={patients}
                    columns={tableSortableHeaderColumn}
                    defaultSorting={{sortByOrder: 'desc', accessor: 'name'}}
                    TableBodyRowComponent={PatientsTableRow}
                />
            );
        } else {
            return <InfoMessage infoMessage="No inactive patients." />;
        }
    } else {
        return <ComponentErrorMessage component="PatientsInactiveTable" />;
    }
};

PatientsInactiveTable.propTypes = {
    patients: PropTypes.array.isRequired,
};

export default PatientsInactiveTable;
