import {LocalStorage} from 'gabi-web-common/util/local-storage';
import {useSyncExternalStore} from 'react';

import {ObservableVar} from '@/util/observable-var';

type DebugFlags = {
    // In the active patients list, allow to show the overlays even when there is nothing to show in them
    alwaysShowTrendsOverlays: boolean;
    // Do not show the small screen view when the viewport is too small
    allowSmallScreen: boolean;
    // Use strict mode (works only in dev mode)
    useStrictMode: boolean;
};

/**
 * This service is used internally to enable debug flags, so developers can debug things more easily.
 */
class DebugService_ {
    debugFlags: ObservableVar<DebugFlags>;

    constructor() {
        this.debugFlags = new ObservableVar<DebugFlags>(JSON.parse(LocalStorage.get('debug_flags', JSON.stringify({
            alwaysShowTrendsOverlays: false,
        } as DebugFlags))));

        this.debugFlags.onChange(() => {
            LocalStorage.set('debug_flags', JSON.stringify(this.debugFlags.value, null, '  '));
        });
    }

    alwaysShowTrendsOverlays(value: boolean) {
        this.debugFlags.set({
            ...this.debugFlags.value,
            alwaysShowTrendsOverlays: value,
        });
    }

    allowSmallScreen(value: boolean) {
        this.debugFlags.set({
            ...this.debugFlags.value,
            allowSmallScreen: value,
        });
    }

    useStrictMode(value: boolean) {
        this.debugFlags.set({
            ...this.debugFlags.value,
            useStrictMode: value,
        });
    }
}

function useDebugFlags() {
    return useSyncExternalStore<DebugFlags>(
        DebugService.debugFlags.onChange,
        () => DebugService.debugFlags.value,
    );
}

const DebugService = new DebugService_();

(window as unknown as { DebugService: DebugService_ }).DebugService = DebugService;

export { DebugService, useDebugFlags };
export type { DebugFlags };
