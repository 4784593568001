import deepEqual from 'deep-eql';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {Beforeunload} from 'react-beforeunload';
import { withTranslation } from 'react-i18next';
import {connect as redux_connect} from 'react-redux';
import ReactRouterPrompt from 'react-router-prompt';
import Styled from 'styled-components';

import PatientCreationCaregiver from '@/components/business/analytics/patient/patient-creation/patient-creation-caregiver';
import PatientCreationChild from '@/components/business/analytics/patient/patient-creation/patient-creation-child';
import {PatientCreationEventConfiguration} from '@/components/business/analytics/patient/patient-creation/patient-creation-event-configuration';
import PatientCreationGroups from '@/components/business/analytics/patient/patient-creation/patient-creation-groups';
import PatientCreationHealthCondition from '@/components/business/analytics/patient/patient-creation/patient-creation-health-condition';
import PatientCreationOnboarding from '@/components/business/analytics/patient/patient-creation/patient-creation-onboarding';
import PatientCreationPrescriber from '@/components/business/analytics/patient/patient-creation/patient-creation-prescriber';
import {Button} from '@/components/buttons/button';
import ErrorBoundary from '@/components/errors/error-boundary';
import {Modal} from '@/components/layout/modal';
import {Page} from '@/decorators/page';
import {colorPalette} from '@/themes/darkmode';

function wrapWithPage(Component) {
    return Page({
        name: 'New patient',
        pagePath: () => ([
            {
                route: 'patients',
                name: 'Patients',
            },
        ])
    })(Component);
}

const reduxMapStateToProps = function(state) {
    return {
        me: state.me,
    };
};

class PatientCreationPage extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        me: PropTypes.object,
        t: PropTypes.func,
    };

    state = {
        steps: [
            this.props.t(['Child information', 'pages.patientCreation.steps.childInformation']),
            this.props.t(['Health condition', 'pages.patientCreation.steps.healthCondition']),
            this.props.t(['Event configuration', 'pages.patientCreation.steps.eventConfiguration']),
            this.props.t(['Caregiver information', 'pages.patientCreation.steps.caregiverInformation']),
            this.props.t(['Prescriber', 'pages.patientCreation.steps.prescriber']),
            this.props.t(['Access groups', 'pages.patientCreation.steps.accessGroups']),
            this.props.t(['Onboarding', 'pages.patientCreation.steps.onboarding']),
        ],
        activeStep: 0,
        patientId: null,
        notSavedDataChild: false,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }
    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <ErrorBoundary>
                    <ReactRouterPrompt when={state.notSavedDataChild}>
                        {({ isActive, onConfirm, onCancel }) =>
                            isActive && (
                                <Modal title={t(['Do you really want to leave?', 'pages.patientCreation.notSavedDataChild.title'])} onClosed={onCancel}>
                                    <p>{t(['Patient is not fully created, already filled data will be lost. Are you sure you want to leave the page?', 'pages.patientCreation.notSavedDataChild.text'])}</p>
                                    <Button displayIcon={false} className="button-delete" type="submit" onClick={onConfirm}>
                                        {t(['Leave the page', 'pages.patientCreation.notSavedDataChild.confirm'])}
                                    </Button>
                                    <Button displayIcon={false} className="button-cancel" type="button" onClick={onCancel}>
                                        {t(['Stay on this page', 'pages.patientCreation.notSavedDataChild.cancel'])}
                                    </Button>
                                </Modal>
                            )
                        }
                    </ReactRouterPrompt>

                    <Beforeunload onBeforeunload={() => 'You’ll lose your data!'} />

                    <div className="sections-wrapper">
                        <ul className="sections-menu wizardSteps">
                            {state.steps.map((step, i) => (
                                <li key={i} className={(state.activeStep === i) ? 'activeStep': (i < state.activeStep) ? 'completedStep': ''}>{step}<span>{i+1}</span></li>
                            ))}
                        </ul>

                        <div className="sections-content">
                            {state.activeStep === 0 &&
                                <PatientCreationChild onCreated={(newPatientId) => this.handlePatientCreated(newPatientId)} onChanged={this.handlePatientChanged} />
                            }
                            {state.activeStep === 1 && state.patientId &&
                                <PatientCreationHealthCondition patientId={state.patientId} onSaved={this.handleStepSaved} />
                            }
                            {state.activeStep === 2 && state.patientId &&
                                <PatientCreationEventConfiguration patientId={state.patientId} onSaved={this.handleStepSaved} />
                            }
                            {state.activeStep === 3 && state.patientId &&
                                <PatientCreationCaregiver patientId={state.patientId} onSaved={this.handleStepSaved} />
                            }
                            {state.activeStep === 4 && state.patientId &&
                                <PatientCreationPrescriber patientId={state.patientId} me={props.me.data} onSaved={this.handleStepSaved} />
                            }
                            {state.activeStep === 5 && state.patientId &&
                                <PatientCreationGroups patientId={state.patientId} onSaved={this.handleStepSaved} />
                            }
                            {state.activeStep === 6 && state.patientId &&
                                <PatientCreationOnboarding patientId={state.patientId} onSaved={this.handleStepSaved} />
                            }
                        </div>
                    </div>
                </ErrorBoundary>
            </div>
        );
    }

    handlePatientChanged(notSavedDataChild) {
        this.setState(state => ({
            ...state,
            notSavedDataChild: notSavedDataChild,
        }));
    }

    handlePatientCreated(newPatientId) {
        this.setState(state => ({
            ...state,
            patientId: newPatientId,
        }), () => {
            this.handleStepSaved();
        });
    }

    handleStepSaved() {
        this.setState(state => ({
            ...state,
            activeStep: this.state.activeStep+1,
            notSavedDataChild: this.state.activeStep+1 > 0 && this.state.activeStep+1 < 4,
        }));
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
    }
}

//language=SCSS
PatientCreationPage = Styled(PatientCreationPage)`
& {
    min-height: 500px;
    width: 90%;
    height: calc(100% - 80px);
    margin: 70px auto 0 auto;
    
    .wizardSteps {
        margin:0;
        padding:0;
        width:300px;
        border-right:1px solid ${colorPalette.clearColor};
        
        li {
            list-style-type: none;
            position: relative;
            padding-right: 40px;
            padding-top: 4px;
            text-align: right;
            height: 60px;
            span {
                display:block;
                padding:5px;
                color:${colorPalette.frontColor};
                border-radius:50px;
                width:30px;
                height:30px;
                text-align:center;
                background-color:${colorPalette.secondBackground};
                position:absolute;
                right:-15px;
                top:0;
            }
            &.activeStep {
                color:${colorPalette.activeColor};
                span {
                  background-color: ${colorPalette.activeColor};
                }
            }
            &.completedStep span {
                background-color:${colorPalette.success};
            }
        }
    }
    
    .sections-content {
        .info-message {
            margin-bottom: 20px;
        }
    }
    
    h3 {
        font-size: 16px;
        margin-bottom: 10px;
    }
    
    .no-group-block {
        width:800px;
    }
}
`;

export default redux_connect(reduxMapStateToProps)(wrapWithPage(withTranslation()(PatientCreationPage)));
