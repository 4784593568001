import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import DatePicker from 'react-datepicker';
import Styled from 'styled-components';

import {Button} from '@/components/buttons/button';
import {CalendarIcon} from '@/components/static/calendar-icon';
import {colorPalette} from '@/themes/darkmode.js';
import {NewDateUtil} from '@/util/new-date-util.js';

class TimetravelWidget extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        onClosed: PropTypes.func.isRequired,
    };

    state = {
        timetravelWidgetOpen: false,
        selectedDate: NewDateUtil(),
    };
    
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        autoBind(this);
    }
    
    componentDidMount() {
        document.addEventListener('click', (e) => {
            if (this.state.timetravelWidgetOpen && this.ref && !this.ref.current.contains(e.target)){
                this.hideTimetravelWidget();
            }
        });
    }
    
    render() {
        const props = this.props;
        const state = this.state;

        return (
            <div className={`timetravel-widget ${props.className}`} ref={this.ref}>
                <div className={`widget-wrapper ${state.timetravelWidgetOpen ? 'active' : ''}`} onClick={this.toggleTimetravelWidget}>
                    <CalendarIcon size={26} color={NewDateUtil.isOverriden() ? '#f44' : '#fff'}/>
                </div>
                
                <div className={`widget-content ${state.timetravelWidgetOpen ? 'active' : ''}`}>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <DatePicker className="form-control"
                            selected={state.selectedDate}
                            maxDate={new Date()}
                            minDate={new Date(2018, 0, 1)}
                            onChange={this.handleDateChange}
                            selectsRange={false}
                            inline
                            dateFormat="dd-MMM-yyyy"
                        />
                        <div className="text-center">
                            <Button className="button-link" onClick={() => {
                                NewDateUtil.overrideTime(null);
                                this.hideTimetravelWidget();
                                location.reload();
                            }}>
                                Clear
                            </Button>
                            <Button className="button-link" onClick={() => {
                                NewDateUtil.overrideTime(state.selectedDate);
                                this.hideTimetravelWidget();
                                location.reload();
                            }}>
                                Apply
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
    
    handleDateChange(newDate) {
        this.setState(state => ({
            ...state,
            selectedDate: newDate,
        }));
    }
    
    hideTimetravelWidget() {
        console.log('HIDE');
        this.setState(state => ({
            ...state,
            timetravelWidgetOpen: false,
        }));
    }
    
    toggleTimetravelWidget() {
        console.log(JSON.stringify(this.state, null, '  '));
        console.log(this);
        this.setState(state => ({
            ...state,
            timetravelWidgetOpen: !state.timetravelWidgetOpen,
        }));
    }
}

//language=SCSS
TimetravelWidget = Styled(TimetravelWidget)`
& {
    display: inline-block;
    position: relative;
    width: 100%;
    min-width: 70px;
    height: 100%;
    border-left: 3px solid ${colorPalette.mainBackground};
    background-size:25px;
    
    .widget-wrapper {
        cursor: pointer;
        z-index: 2;
        position: relative;
        padding: 0 10px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        
        &.active {
            background: ${colorPalette.activeColorDarker};
        }
    }

    .widget-content {
        position: fixed;
        background: ${props => props.theme.colorPalette.mainBackground};
        margin: 0;
        list-style: none;
        box-shadow: 0 2px 4px rgba(0,0,0,0.2);
        overflow: hidden;
        transition: opacity 0.15s ease-out, max-height 0.5s ease-out;
        opacity: 0;
        z-index: 1;
        max-height: 0;
        padding:0;

        &.active {
            max-height: 1000px;
            opacity: 1;
            display: block;
            transition: opacity 0.15s ease-out;
        }
    }
}`;

export { TimetravelWidget };

