import {useAuth0} from '@auth0/auth0-react';
import {useEffect, useRef, MouseEvent as ReactMouseEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link } from 'react-router-dom';
import Styled from 'styled-components';

import {FlexChild, FlexLayout} from '@/components/layout/flex-layout';
import {UserPic} from '@/components/static/user-pic';
import config from '@/config';
import groupsIcon from '@/public/icons/groups-icon.svg';
import hcpDefaultPic from '@/public/icons/hcp-default-pic.svg';
import logoutIcon from '@/public/icons/logout-icon.svg';
import profileIcon from '@/public/icons/user-icon.svg';
import {colorPalette, medias} from '@/themes/darkmode';
import {appVersion} from '@/util/app-version-util';

type UserMenuProps = {
    className?: string;
    user?: {
        name: string,
        email: string;
    };
    direction?: 'top' | 'bottom'; //default: bottom
    picLocation?: 'left' | 'right'; //default: right
};
function UserMenuUnstyled({className, direction, picLocation, user}: UserMenuProps) {
    const [userMenuOpen, setUserMenuOpen] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);
    const { t } = useTranslation();
    const auth0 = useAuth0();
    user = user ?? { name: '', email: '' };
    direction = direction ?? 'bottom';
    picLocation = picLocation ?? 'right';

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick, { capture: true });
        return () => {
            document.removeEventListener('click', handleDocumentClick, { capture: true });
        };
    }, [userMenuOpen]);

    const handleDocumentClick = (e: MouseEvent) => {
        const isTargetNotInMenu = !(e.target instanceof HTMLElement) || !(ref.current?.contains(e.target) ?? false);
        if (userMenuOpen && isTargetNotInMenu) {
            setUserMenuOpen(false);
        }
    };

    const handleClickSignOut = (e: ReactMouseEvent) => {
        e.preventDefault();
        auth0.logout({
            returnTo: config.auth0.returnTo,
        });
    };

    const hideUserMenu = () => setUserMenuOpen(false);
    const toggleUserMenu = () => setUserMenuOpen(userMenuOpen => !userMenuOpen);

    const renderSubMenu = () => {
        return (
            <ul className={`menu-items ${userMenuOpen ? 'active' : ''} direction-${direction ?? 'bottom'}`}>
                <li className="menu-item">
                    <Link to="/profile" className="menu-item-profile" onClick={hideUserMenu}>{t(['Profile', 'userMenu.profile'])}</Link>
                </li>
                <li className="menu-item">
                    <Link to="/groups" className="menu-item-groups" onClick={hideUserMenu}>{t(['Groups management', 'userMenu.groups'])}</Link>
                </li>
                <li className="menu-item">
                    <a className="menu-item-logout" onClick={handleClickSignOut}>{t(['Log out', 'userMenu.logout'])}</a>
                </li>
            </ul>
        );
    };

    return (
        <div className={`user-menu ${className ?? ''}`} ref={ref}>
            {direction === 'top' && renderSubMenu()}

            <div className={`user-wrapper align-${picLocation}`} onClick={toggleUserMenu}>
                <FlexLayout direction={picLocation === 'left' ? 'row' : 'row-reverse'}>
                    <FlexChild className="user-pic-wrapper">
                        <div className="user-pic">
                            <UserPic pic={hcpDefaultPic} alt={user.name} />
                        </div>
                    </FlexChild>
                    <FlexChild grow={1} className="user-menu__user-name">
                        <div className="d-none d-md-inline-block">
                            <strong>{(user.name) ? user.name : user.email}</strong><br/>
                            <span className="user-menu__app-version">v{appVersion}</span>
                        </div>
                    </FlexChild>
                </FlexLayout>
            </div>

            {direction === 'bottom' && renderSubMenu()}
        </div>
    );
}

//language=SCSS
const UserMenu = Styled(UserMenuUnstyled)`
& {
    display: inline-block;
    position: relative;
    min-width: 70px;
    height: 100%;
    border-left: 3px solid ${colorPalette.mainBackground};
    
    .user-wrapper {
        cursor: pointer;
        z-index: 2;
        position: relative;
        padding: 0 10px;
        height: 100%;
    }
    
    .user-pic-wrapper {
        line-height: 0;
        vertical-align: middle;
        border-radius: 100%;
        overflow: hidden;
        align-self: center;
    }
    
    .user-pic {
        display: inline-block;
        line-height: 0;
        position: relative;
        width: 40px;
        height: 40px;
        border: none;
        vertical-align: middle;
        outline: transparent;
        text-align: center;
        
        > img {
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }
    
    .user-menu__app-version {
        color: ${colorPalette.clearColor};
    }
    
    .user-menu__user-name {
        display: inline-block;
        margin: 0;
        padding: 0 10px 0 0;
        align-self: center;
        width: 205px;
        @media screen and (${medias.xlMin}) {
            width: 285px;
        }
        line-height: 1.1em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; // This is where the magic happens
        @media(${medias.smMin}) {
            white-space: nowrap;
        }
        
        strong {
            display: inline-block;
            width: 100%;
        }
        
        > div {
            width: 100%;
            > * {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    
    ul.menu-items {
        position: fixed;
        width: 100%;
        background: ${props => props.theme.colorPalette.mainBackground};
        margin: 0;
        list-style: none;
        box-shadow: 0 2px 4px rgba(0,0,0,0.2);
        overflow: hidden;
        transition: opacity 0.15s ease-out, max-height 0.5s ease-out;
        opacity: 0;
        z-index: 1;
        max-height: 0;
        padding:0;
        
        &.direction-bottom {
          top: -5px;
          padding-top: 74px !important;
        }
        &.direction-top {
          bottom: -15px;
          padding-bottom: 75px !important;
        }
        
        &.active {
            max-height: 1000px;
            opacity: 1;
            display: block;
            transition: opacity 0.15s ease-out;
        }
    }
    
    .menu-item {
        margin: 0;
        cursor: pointer;
        padding: 0;
        color: ${props => props.theme.colors.menu.text};
        white-space: nowrap;
        line-height: 40px;
        &:hover {
            background: ${props => props.theme.colors.menu.backgroundHover};
            color: ${props => props.theme.colors.menu.textHover};
        }
        > a {
            display: block;
            padding: 0 0 0 75px;
            cursor: pointer;
            color: ${props => props.theme.colors.menu.text};
            border-bottom:1px solid ${colorPalette.thirdBackground};
            border-top:1px solid ${colorPalette.darkColor};
          
            &:hover {
                color: ${props => props.theme.colors.menu.textHover};
                text-decoration: none;
            }

            &.menu-item-profile {
                background: url(${profileIcon}) no-repeat 25px center;
                background-size:15px;
            }

            &.menu-item-groups {
                background: url(${groupsIcon}) no-repeat 17px center;
                background-size:30px;
            }

            &.menu-item-logout {
                background: url(${logoutIcon}) no-repeat 22px center;
                background-size:19px;
            }
        }
        &:first-child a {
          border-top:none;
        }
        &:last-child a {
          border-bottom:none;
        }
    }
}`;

export {UserMenu};
