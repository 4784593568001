import type {ServiceInfo} from '@protobuf-ts/runtime-rpc';
import {JSX} from 'react';

import {useBackendQuery, useBackendQueryArgs} from '@/hooks/use-backend-query';
import {NoExtraProp} from '@/types/no-extra-prop';

type Props = { [key: string]: unknown };

export function withBackendQuery<ServiceClient extends ServiceInfo, Request extends object, Response extends object>
(loadingName: string, resourceName: string, errorName: string, optionsFn: (props: Props) => NoExtraProp<useBackendQueryArgs<ServiceClient, Request, Response>>, dependencies?: unknown[])
: (component: JSX.ElementType) => JSX.ElementType {

    return (Component: JSX.ElementType) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function ComponentWithQueryProp(props: Props) {
            const options = optionsFn(props);

            const [loading, resource, error] = useBackendQuery(options, dependencies);

            const extraProps: Props = {};
            extraProps[loadingName] = loading;
            extraProps[resourceName] = resource;
            if (errorName) {
                extraProps[errorName] = error;
            }

            return (
                <Component {...props} {...extraProps} />
            );
        }

        return ComponentWithQueryProp;
    };
}
