import {Suspense, StrictMode, PropsWithChildren} from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';

import LoadingView from '@/components/static/loading-view';
import routes from '@/routes-analytics';
import {Auth0Service} from '@/services/auth0-service';
import {useDebugFlags} from '@/services/debug-service';
import {ErrorTrackingService} from '@/services/error-tracking-service';
import {GabiEnvService} from '@/services/gabi-env-service';
import '@/services/i18n-service';
import store from '@/store';
import * as theme from '@/themes/darkmode';

// Get application routes
const router = createBrowserRouter(routes);

// Initialize Sentry
ErrorTrackingService.init();

function ConditionalStrictMode({children}: PropsWithChildren) {
    const debugFlags = useDebugFlags();
    return debugFlags.useStrictMode ? (
        <StrictMode>{children}</StrictMode>
    ) : (
        <>{children}</>
    );
}

// ENTRY POINT OF GABI ANALYTICS APPLICATION
GabiEnvService.init().then(() => {
    ReactDOM.createRoot(document.getElementById('app')!).render(
        <ConditionalStrictMode>
            <Provider store={store}>
                <Suspense fallback={<LoadingView />}>
                    <ThemeProvider theme={Object.assign({}, theme)}>
                        <RouterProvider router={router} />
                    </ThemeProvider>
                </Suspense>
            </Provider>
        </ConditionalStrictMode>
    );
});

// Check session - redirect to login page after token expiration
function sessionCheck() {
    Auth0Service.getAccessToken()
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.error(error);
            // Reload application to display login page when token expired
            location.reload();
        });
}

setInterval( function() {
    sessionCheck();
}, 900000);
